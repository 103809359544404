
import { gsap } from "gsap";
import {default as store} from "../components/filterStore";
import Filter from "../classes/filter";
import getPager from '../components/pager';
import getItems from "../components/getItems";
import DropdownGroup from "../dropdownGroup";
import shopItemsLayout from "../components/shopItemsLayout";
import imagesLoaded from "imagesloaded";
import Lazy from "vanilla-lazyload";
import {Q} from '../Jesus';
import {isElementIn, parseQuery} from "../functions";
import getFilter from "../components/getFilter";
import catNav from "./catNav";
import {ui, updateModules} from "../components/moduleHandler";

export default ({element: rootEl, data:{
    hiddenParams = null,
    url,
    filterUrl,
} = {}}) => {

    ui.catNav = catNav;
    updateModules();

    const pager = getPager().init();

    const filter = new Filter({
        store,
        hiddenParams,
        url,
        filterUrl,
        pager,
        rootElement: rootEl
    });

    pager.hiddenParams = (hiddenParams || []).reduce((accum, v) => ({
        ...accum,
        ...parseQuery(v)
    }), {});

    const container = document.querySelector('.shopItems');
    const layout = shopItemsLayout(container);
    const lazyImages = new Lazy({
        elements_selector: ".shopItem-image_idle",
        callback_loaded: el => el.classList.remove('shopItem-image_idle')
    });

    imagesLoaded(container.QA('.shopItem-image_idle'), () => {
        gsap.set('.loader', {display: 'none'})
    });

    const dropdown = new DropdownGroup(document.querySelector('.filter .eppo-dropdown-group'), {
        onActivateItem: ({active}) => {
            const f = active.querySelector('.type-filter');
            f && !DropdownGroup.isTouch && setTimeout(() => {
                f.focus();
            },50)
        },
        onDeactivateItem: ({active}) => {
            const f = active.querySelector('.type-filter');
            f && !DropdownGroup.isTouch && setTimeout(() => {
                f.blur();
            },50)
        }
    });

    const handleFilterDropdown = (event) => {
        const groups = event.active.QA('.filter-group');

        for(var i = 0; i < groups.length; i++) {

            const groupElement = groups[i];
            if(!!groupElement.classList.contains('ignore_ajax_filter')) {continue;}

            handleFilterGroup(groupElement);
        }
    }

    const handleFilterGroup = (groupElement) => {
        const groupName = groupElement.getAttribute('name');
        const query = filter.toQueryString([groupName]);

        const isSorted = groupElement.classList.contains('filter-sorted');

        groupElement.classList.add('loading');
        groupElement.classList.remove('fail');

        getFilter(filterUrl + query, groupName)
            .catch((err) => {
                groupElement.classList.add('fail');
                console.log(err)
            })
            .then(res => {
                handleFilterResponse(res, {
                    groupElement,
                    groupName,
                    isSorted
                })
            })
    }

    const handleFilterResponse = (data, {
        groupElement,
        groupName,
        isSorted = false,
    }) => {

        groupElement.classList.remove('loading');

        if(!data) return;

        if(groupElement.dataset.filterUi === 'booleans') {

            var booleans = toArray(groupElement.querySelectorAll('[type="radio"], [type="checkbox"]'));

            var sorted = [],
                list;
            var visibleCount = 0,
                checkedCount = 0;

            for(var i = 0; i < booleans.length; i++) {
                var bool = booleans[i];
                var parent = bool.parentElement;
                var countEl = parent.getElementsByClassName('count')[0];

                parent.filterSort = -1;

                if(countEl) {
                    var hits = data[bool.id];
                    hits = hits ? hits : 0;
                    if(hits || bool.checked) { //hits

                        parent.filterSort = isSorted ? 1 : parseInt(hits);

                        if(bool.checked) {
                            parent.filterSort += 10000;
                            checkedCount ++;
                        }
                    }else {
                        parent.filterSort = -1;
                    }
                    countEl.textContent = '('+ hits + ')'
                } else {
                    parent.filterSort = 10000000;
                }
                sorted.push(parent);

            }

            if(groupElement.clearGroupReference) {
                groupElement.clearGroupReference.checked = !checkedCount;
            }

            if(!isSorted) {
                sorted = sorted.sort((a, b) => Math.sign(b.filterSort - a.filterSort));
            }

            list = sorted[0].parentElement;
            var sortedFragment = document.createDocumentFragment();

            for (var j = 0; j < sorted.length; j++) {
                var el = sorted[j];
                sortedFragment.appendChild(el);
                if(visibleCount < 1000 && el.filterSort > 0) {
                    el.style.removeProperty('display');
                    visibleCount++
                }else {
                    el.style.display = 'none'
                }
            }
            list.appendChild(sortedFragment);
        }

        var typeFilter = groupElement.getElementsByClassName('type-filter')[0];

        if(typeFilter && typeFilter.value.length > 1) {
            typeFilter = typeFilter.eppo.typeFilter;
            typeFilter.update();
            typeFilter.filter(typeFilter.value);
        }
    }

    const handleGet = (url) => {

        layout.remove(container.QA('.shopItem'));
        gsap.set('.loader', {display: 'block'})

        getItems(url)
            .then(({items, images, nextUrl, total, ...rest}) => {

                if (!items) return;

                gsap.set(items, {visibility: 'hidden'})
                pager.setData(nextUrl, total);
                layout.insert(items);

                imagesLoaded(images, () => {
                    gsap.set(items, {clearProps: 'visibility'})
                    layout.layout();
                    pager.update();
                    gsap.set('.loader', {display: 'none'})
                    lazyImages.update();
                });
            });
    }

    const handlePagerNavigate = (e) => {
        filter.pushHistory.call(filter, e.url);
        handleGet(url + e.query)
    }

    const handleClearGroup = (target, {event}) => {
        event.preventDefault();
        const group = isElementIn(target, filter.groups, 10);

        if(group.dataset.filterUi === 'booleans') {

            const bools = group.QA('[type="checkBox"]:not(.clear-filter-group), [type="radio"]:not(.clear-filter-group)');
            var change = false;

            for(let b = 0; b < bools.length; b++) {
                var bool = bools[b];
                if(!change) {
                    change = bool.checked;
                }
                bool.checked = false;
            }

            // Always submit, or radios will not work
            filter.submitFilter();
        }
    };

    const handleBoolChange = (target, e) => {
        const group = isElementIn(target, filter.groups, 10);
        const checked = group.QA('[type="checkBox"]:not(.clear-filter-group):checked, [type="radio"]:not(.clear-filter-group):checked');

        if(group.classList.contains('filter-bool-range')) {
            if(checked.length > 1) {
                const bools = group.QA('[type="checkBox"]:not(.clear-filter-group), [type="radio"]:not(.clear-filter-group)');

                const startIndex = bools.indexOf(checked[0]);
                const endIndex = bools.indexOf(checked[checked.length-1]);
                const targetIndex = bools.indexOf(target);

                if(endIndex === targetIndex || startIndex === targetIndex) {
                    for(let i = (startIndex); i < endIndex; i++) {
                        bools[i].checked = true;
                    }
                }else {
                    let removeStart, removeEnd;
                    const countBelow = (targetIndex - startIndex);
                    const countAbove = (endIndex - targetIndex);
                    const removeBelow = countAbove > countBelow;

                    removeStart = removeBelow ? startIndex : targetIndex;
                    removeEnd = removeBelow ? targetIndex : endIndex;

                    for(var j = removeStart; j <= removeEnd; j++) {
                        bools[j].checked = false;
                    }
                }
            }
        }

        const allCheck = group.Q('.clear-filter-group');

        if(allCheck) {
            allCheck.checked = !checked.length;
        }

        filter.submitFilter();
    }

    const handleFilterChange = (e) => {

        let hit;

        switch (true) {
            case !!(hit = isElementIn(e.target, filter.clearGroupBools)):
                handleClearGroup(hit, e);

                break;

            case !!(hit = isElementIn(e.target, filter.bools)):
                handleBoolChange(hit, e);

                break;

            case e.target.type === 'hidden':
                e.target.value = null;
                filter.submitFilter();

                break;
        }

        const group = isElementIn(e.target, filter.groups, 10);

        if (group && group.dataset.filtersiblings) {

            const siblings = JSON.parse(group.dataset.filtersiblings);
            siblings.forEach(function(sibling) {
                handleFilterGroup(filter.getGroupByName(sibling))
            });
        }
    };

    Q('.shop-filters .toggle-filter').addEventListener('click', (e) => {

        e.preventDefault();
        const {currentTarget} = e;
        const el = Q('.shop-filters .desktop');
        el.classList.toggle('active');
        currentTarget.innerText = el.classList.contains('active')
            ? 'Skjul FILTER'
            : 'Vis FILTER';
    });

    pager.events.addListener('navigate', handlePagerNavigate);
    dropdown.events.addListener('activateItem', handleFilterDropdown);
    filter.store.addListener('change', handleFilterChange)
    filter.store.addListener('query', ({query:{query, clientQuery}}) => {
        handleGet(`${url}${query}`)
    })
}
