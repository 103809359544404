import classnames from "classnames";
import React from "react";
import {thousandSeparate} from "../../functions";

const priceFormat = (p) => thousandSeparate(p, ' ') + ',-';
const noop = () => {};

export default (props) => {

  const {
    frame_key,
    name,
    price,
    w_mm: w,
    d_mm: d,
    onClick = noop,
    selected,
    edocPrefix,
    noLazyLoad
  } = props;

  const src = `${edocPrefix}frame/${frame_key}-detalj.jpg`;

  return (
    <li key={frame_key}
        onClick={() => onClick(props)}
        className={classnames(
          'FramePicker-entry', {
            'FramePicker-entry_selected': selected && frame_key === selected.frame_key
          }
        )}>
      <img className={`FramePicker-entry-img -img ${noLazyLoad ? 'loaded' : ''}`}
           data-src={!noLazyLoad ? src : null}
           src={noLazyLoad ? src : null}
           alt={frame_key}
      />
      <div className="FramePicker-entry-description">
        <span className="-name">{name} <span className='-key'>{frame_key}</span></span>
        <span className="-dimensions">{`${w}x${d}mm (BxD)`}</span>
        <span className="-price">{priceFormat(price)}</span>
      </div>
    </li>
  )
}
