import workshop from "../workshop";
import {Q} from "../Jesus";
import ctaBar from "../ctaBar";
import productForm from "../components/productForm";
import {ui, updateModules} from "../components/moduleHandler";
import framePicker from "./framePicker";
import productGallery from "./productGallery";
import Lazy from "vanilla-lazyload";

export default ({element, data:{type}}) => {

    const form = productForm.getInstance();

    ui.framePicker = framePicker;
    ui.productGallery = productGallery;

    new Lazy({
        elements_selector: ".__productImage",
        thresholds: '0px 0px 0px 0px',
    });

    switch (type) {
        case 'workshop':
            workshop({
                root: Q('.workshop')
            }).init();

            break;

        case 'plain':
            ctaBar(Q('.ctaBar'))

            break;
    }

    updateModules();
}
