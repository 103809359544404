import SimpleSlider from "../components/simpleSlider";

import {C, QC} from '../Jesus';

export default (token) => {

    const {element:rootElement} = token;

    const sliderCandidate = rootElement.Q('.docSlider-items');
    const sliderNavCandidate = rootElement.Q('.docSlider-thumbsList');

    if(!sliderNavCandidate) return;

    var slidingItems = new SimpleSlider(sliderCandidate, {
        constrainDrag: true
    });

    if(slidingItems.invalid) {
        slidingItems.destroy();
        rootElement.remove();
        return token;
    }

    var slidingThumbs = new SimpleSlider(sliderNavCandidate, {
        constrainDrag: false
    });


    //// NAV ->

    const sliderThumbItems = sliderNavCandidate.QC('docSlider-thumbWrap'); // Thumbs
    const sliderNavItems = QC('docSlider-navItem'); // Arrows

    sliderThumbItems.forEach((item, i) => {

        item.slider = {
            nav: 'index',
            i,
        };
        item.addEventListener('click', navItemClickHandler)
    });

    sliderNavItems.forEach((item, i) => {

        item.slider = {
            nav: i && 'next' || 'prev',
        };
        item.addEventListener('click', navItemClickHandler)
    });

    function navItemClickHandler() {

        switch (this.slider && this.slider.nav) {
            case 'index':
                slidingItems.goTo(this.slider.i);
                break;

            case 'next':
                slidingItems.goTo(slidingItems.activeIndex +1);
                break;

            case 'prev':
                slidingItems.goTo(slidingItems.activeIndex -1);
                break;

        }
    }

    const descriptionField = C('div', {'class': 'docSlider-description'});
    rootElement.Append(descriptionField);

    function setActiveThumb(index) {

        const activeThumbClassName = 'docSlider-thumbWrap_active';
        const activeThumb = sliderNavCandidate.Q(`.${activeThumbClassName}`);

        activeThumb && activeThumb.classList.remove(activeThumbClassName);

        const newActiveThumb = slidingThumbs.cells[index];
        const desc = newActiveThumb.Q('img').getAttribute('alt');

        desc && desc.length
            && (descriptionField.Text(desc).RemoveClass('hidden'))
            || (descriptionField.Text('').AddClass('hidden'));

        newActiveThumb.classList.add(activeThumbClassName);
    }

    function setNavItemsStatus() {

        const disabledClassName = 'docSlider-navItem_disabled';
        sliderNavItems.forEach(item => item.classList.remove(disabledClassName));
        slidingItems.isFirst && sliderNavItems[0].classList.add(disabledClassName);
        slidingItems.isLast && sliderNavItems[1].classList.add(disabledClassName);
    }

    setActiveThumb(slidingItems.activeIndex);
    setNavItemsStatus();

    slidingItems.on('activate', e => {

        slidingThumbs.goTo(e.activeIndex);
        setActiveThumb(e.activeIndex);
        setNavItemsStatus();
    });

    //// NAV -|

    return token
};