import simpleState from "../../classes/simpleState";
import Events from 'eventemitter3';
import {QA, QC} from '../../Jesus';
import {modal} from "../modal";
import Login from "../../reactComponents/Login";
import request from 'superagent';
import {elementWithinClass} from "../../functions";

class FavList extends simpleState {

    constructor(...args) {
        super(...args);
    }

    events = new Events;

    change(prop, state, prevState) {
        this.events.emit(prop, state, prevState)
    }

    addListener(event, fn) {
        this.events.addListener(event, fn)
    }

    removeListener(event, fn) {
        this.events.removeListener(event, fn)
    }

    async getList() {

        if(!glob.isLoggedIn) return;

        this.setState({
            loading: true
        });

        const res = await request.get('/favoritter')
            .then(res => res.body)
            .catch(err => {
                switch (err.status) {
                    case 401:
                        console.log('unauthorized, show login');
                        break;

                    default:
                        console.warn(`unhandled response code ${err.status}`);
                        console.error(err);
                }
            });

        this.handleListResponse(res && res.items || []);
    }

    toggle({itemId = null, productId = null, wishlineIds, context}) {

        const action = wishlineIds ? 'delete': 'insert';

        this.trackToggle({
            itemId: itemId || productId,
            context,
            action
        });

        if (action === 'delete') {
            this.delete(wishlineIds)
            return;
        }

        this.insert({
            itemId,
            productId
        });
    }

    trackToggle({itemId, context, action}) {

        if (typeof gtag === 'undefined') return;

        gtag && gtag('event', action === 'insert' ? 'add_favourite' : 'del_favourite', {'event_category': context.toLowerCase(), 'event_label': itemId });
    }

    trackNavigate({itemId}) {

        if (typeof gtag === 'undefined') return;

        gtag && gtag('event', 'view_fav_details', {'event_category': 'favourites', 'event_label': itemId });
    }

    handleListResponse(items = []) {

        this.setState({
            loading: false,
            items: items.reverse(),
        });
    }

    async insert({itemId = null, productId = null}) {

        this.setState({
            loading: true
        });

        const res = await request.post('/favoritter')
            .send({
                ins_x: 1,
                item_id: itemId,
                product_id: productId
            })
            .type('form')
            .then(res => res.body);

        this.handleListResponse(res && res.items || []);
    }

    async delete(wishlineIds = []) {

        this.setState({
            loading: true
        });

        const res = await request.post('/favoritter')
            .send({
                del_x: 1,
                wishline_ids: wishlineIds
            })
            .type('form')
            .then(res => res.body);

        this.handleListResponse(res && res.items || []);
    }
}

const favList = new FavList({
    items:[],
    loading: false,
    panelOpen: false
});

// Handle clicks for non reactive DOM elements
window.addEventListener('click', (e) => {

    const favMe = elementWithinClass(e.target, 'ui_favMe');
    const {isLoggedIn} = window.glob;

    if(favMe) {

        e.preventDefault();

        if (!isLoggedIn) {
            modal.setComponent({
                Component: Login
            })
                .open();
            return;
        }

        const {itemId, productId, context, wishlineIds} = favMe.dataset;

        favList.toggle({
            context,
            itemId,
            productId,
            wishlineIds: wishlineIds && JSON.parse(wishlineIds) || null
        })
    }
});

// Manipulate non reactive DOM elements on list change
favList.addListener('items', ({items}, {items:oldItems}) => {

    const a = performance.now();

    const favMes = QC('ui_favMe');

    favMes.forEach(el => {
        el.classList.remove('favMe_isFav');
        el.dataset.wishlineIds = '';
    });

    items.forEach(item => {

        const favMes = QA(`.ui_favMe[data-product-id="${item.product_id}"]`);

        favMes.forEach(el => {

            let wishlineIds = el.dataset.wishlineIds
                ? JSON.parse(el.dataset.wishlineIds)
                : [];

            wishlineIds = [...wishlineIds, item.wishline_id];

            el.classList.add('favMe_isFav');
            el.dataset.wishlineIds = JSON.stringify(wishlineIds);
        })
    });

    // const favMeElements = favMes.reduce((res, el) => {
    //
    //     const inNew = items.some(item => item.item_id == itemId);
    //     const inOld = oldItems.some(item => item.item_id == itemId);
    //
    //     if(inNew != inOld) {
    //         inNew && res.add.push(el);
    //         inOld && res.remove.push(el);
    //     }else {
    //         // console.log(itemId);
    //     }
    //
    //     return res;
    // }, {
    //     add: [],
    //     remove: []
    // });

    // console.log(favMeElements);
});

export default favList;
