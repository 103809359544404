import { gsap } from "gsap";
import { Power2 } from "gsap";

export default ({element}) => {

    const state = {stuck: false}
    const relatedEl = document.getElementById('artist-artworks');

    const passedThreshold = () =>
        (relatedEl.getBoundingClientRect().top - window.innerHeight + relatedEl.clientHeight) < 0;

    const stick = () => {
        state.stuck = true;
        gsap.set(element, { clearProps: 'all'})
    }

    const unstick = () => {
        state.stuck = false;
        gsap.set(element, { yPercent: 100, visibility: 'hidden'})
    }

    const handleScroll = e => {
        !state.stuck && !passedThreshold() && stick();
        state.stuck && passedThreshold() && unstick();
    }

    element.addEventListener('click', () => gsap.to(window, { duration: .7,
        scrollTo: relatedEl,
        ease: Power2.easeInOut
    }))

    handleScroll();

    relatedEl && window.addEventListener('scroll',  handleScroll)
}
