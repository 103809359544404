
window.javawin = function javawin(winurl, winname, width, height)
{
    window.winfeatures = 'toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=1,resizable=0,width=' + width + ',height=' + height;
    window.win = window.open(winurl, winname, winfeatures);
}

import iziToast from 'izitoast';

window.vendo_message = function vendo_message(severity, message, opts) {
    iziToast[severity]({message:message, position: "topRight"});
};
