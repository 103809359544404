import {modal} from "./modal";
import React,  {useEffect} from "react";
import SvgIcon from "../reactComponents/SvgIcon";
import Lazy from "vanilla-lazyload";

export default ({element, data:{src}}) => {

    if(!src) return;

    const openBox = (src) => {
        modal.setComponent({
            Component: ({onClose}) => {

                const handleKeyUp = ({code}) => {
                    code === 'Escape' && onClose()
                }

                useEffect(() => {
                    window.addEventListener('keyup', handleKeyUp)

                    return () => window.removeEventListener('keyup', handleKeyUp)
                }, [])

                return (
                    <div className={'imageBox-inner'}>
                        <button className="imageBox-closeBtn flex items-center justify-center" onClick={onClose}>
                            <SvgIcon name='cross'/>
                        </button>
                        <img src={src} alt=""/>
                    </div>
                )
            },
            modalProps: {
                className: 'imageBox'
            }
        })
            .open()
    }

    element.addEventListener('click', e => {
        e.preventDefault();

        new Lazy({
            elements_selector: ".lazy",
            thresholds: '0px 0px 0px 0px',
        });

        openBox(src)
    })
}