import React, {Component} from 'react';
import Panel from "./Panel/Panel";

const Loader = () => {
    return (
        <div className={'loader'}>...laster innhold</div>
    )
};

export default class ModalContainer extends Component {

    constructor(props) {
        super(props);
        this.events = props.events;

        this.events.addListener('toggle', (e) => {
            this.setState({
                isOpen: !this.state.isOpen
            })
        });

        this.events.addListener('close', (e) => {
            this.setState({
                isOpen: false
            })
        });

        this.events.addListener('open', (e) => {
            this.setState({
                isOpen: true
            })
        });

        this.events.addListener('setComponent', ({Component, props = {}, modalProps = {}}) => {
            this.setState({
                html: null,
                props,
                Component: Component,
                modalProps
            })
        });

        this.events.addListener('setHtml', ({html}) => {
            this.setState({
                Component: null,
                html,
            })
        });
    }

    state = {
        isOpen: false,
        Component: null,
        html: null,
    };

    componentDidMount() {
    }

    closePanel = () => {
        this.setState({
            isOpen: false
        })

        return true;
    };

    render() {
        const {
            isOpen,
            Component,
            html,
            props,
            modalProps
        } = this.state;

        const {onClose} = this.state.props || {};
        const closeAction = () => {
            if (onClose) {
                onClose() && this.closePanel()
            } else {
                this.closePanel();
            }
        };

        return (
            <Panel
                onClose={closeAction}
                type={'Modal'}
                html
                {...{isOpen}}
                {...modalProps}>
                {Component && <Component {...props} onClose={closeAction}/> || null}
                {!Component && html && <div dangerouslySetInnerHTML={{__html: html}}/>}
                {!Component && !html && <Loader/>}
            </Panel>
        )
    }
}
