import React from 'react';
import ListItem, {mapServerData as mapItem} from "../ListItem";
import {modal} from "../../modules/modal";
import Login from "../Login";

export default ({list, onDeleteFav, onNavigate, isLoggedIn}) => {

    const handleLoginClick = (e) => {
        e.preventDefault();
        modal.setComponent({
            Component: Login,
        })
            .open();
    };

    return <div className='userList userList_wishList'>

        {!isLoggedIn &&
        <div className="userList-placeholder">
            <a href="#" onClick={handleLoginClick}>Logg inn</a> {' eller '}
            <a href='/register'>registrer deg {' '}</a>
            <span dangerouslySetInnerHTML={{__html: 'for &aring; se dine favoritter'}}/>
        </div>
        || null}

        {isLoggedIn && !list.length &&
        <div className="userList-placeholder">
            Du har ingen favoritter enda.
        </div>
        || null}

        {isLoggedIn && list.length &&
        list.map(
            (entry) => {
                const itemData = {
                    ...mapItem(entry),
                    onDelete: onDeleteFav,
                    onNavigate,
                    isWishline: true,
                };

                return <ListItem modClassNames={['favList', 'wishLine']} {...itemData}/>
            }
        ) || null}
    </div>
};
