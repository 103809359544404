import imagesLoaded from 'imagesloaded';
import SimpleSlider from "../components/simpleSlider";
import {withSelectorCallback} from "../functions";

export default ({element: root}) => {

    const entries = root.QA('.artwork');
    const loader = root.Q('.loader');
    const bg = root.Q('.bg');
    const sliderCandidate = root.Q('.main').AddClass('pSlider')

    entries.forEach(el => el.classList.add('pSlider-item'));

    imagesLoaded(sliderCandidate.QA('img'), () => {
        bg && bg.classList.add('visible');
        loader && loader.classList.add('hidden');

        if (entries.length < 2) return;

        const slider = new SimpleSlider(sliderCandidate, {
            constrainDrag: true
        })

        const thumbs = root.Q('.pSlider-thumbs');

        if (!thumbs) return;

        const thumbList = root.QA('.pSlider-thumb');

        thumbs.addEventListener('click', withSelectorCallback('.pSlider-thumb',(e, target) => {
            slider.goTo(thumbList.indexOf(target));
        }))

        slider.on('activate', ({activeIndex}) => {
            thumbs.QA('.pSlider-thumb_active').forEach(el => el.classList.remove('pSlider-thumb_active'));
            thumbList[activeIndex].classList.add('pSlider-thumb_active');
        })

        thumbList[0].classList.add('pSlider-thumb_active');
    })
}