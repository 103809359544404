import {modal} from "./modal";
import Login from "../reactComponents/Login";
import {elementWithinClass} from "../functions";

const clickLoginHandler = (e) => {

    const hit = elementWithinClass(e.target, 'loginTrigger');

    if(!hit || window.glob.isLoggedIn) return;

    e.preventDefault();
    e.stopPropagation();

    modal.setComponent({
            Component: Login
        })
        .open();
};

window.addEventListener('click', clickLoginHandler);
export default (token) => token;