import { gsap } from "gsap";
import { Power2 } from "gsap";
import imagesLoaded from 'imagesloaded';
import request from 'superagent';
import {isElement, withSelectorCallback} from "../functions";
import EventedState from "../classes/eventedState";

export default ({element: rootEl}) => {

    const store = new EventedState({
        activeItem: null,
        preview: null,
    });

    const styleEl = document.createElement('style');

    document.head.appendChild(styleEl);

    const addPreview = (preview) => {

        preview.addEventListener('click', handleCloseClick)
        document.body.appendChild(preview);

        const {activeItem} = store.state;
        const {top, bottom, left, width} = activeItem.getBoundingClientRect();
        const badFit = window.innerHeight < (preview.clientHeight + activeItem.clientHeight);

        styleEl.Html(`.artist-preview:after {left: ${left + width / 2}px;}`)

        gsap.set(preview, {top: bottom + 20 + window.scrollY})
        gsap.from(preview, { duration: .25, ease: Power2.easeOut, opacity: 0,})
        gsap.to(window, { duration: .7,
            ease: Power2.easeInOut,
            scrollTo: badFit
                ? bottom - 20 + window.scrollY
                : Math.max(top + window.scrollY, window.scrollY),
        })
    }

    const getPreview = url => request(url)
        .then(res => document.createElement('div')
            .Html(res.text).children[0]
        );

    const removePreview = (preview) => {

        preview.removeEventListener('click', handleCloseClick)
        styleEl.Html('');

        gsap.to(preview, { duration: .3,
            ease: Power2.easeIn,
            opacity: 0,
            onComplete: () => preview.remove()
        })
    }

    const close = () => store.setState({
        activeItem: null,
        preview: null,
    })

    const handleCloseClick = (withSelectorCallback('.close', close));

    const handleActiveItem = ({activeItem}, {activeItem: _activeItem}) => {

        _activeItem?.classList.remove('active');
        activeItem?.classList.add('active');

        const url = activeItem?.children[0].dataset.ajax || null;

        url && getPreview(url)
            .then((preview) => {
                store.setState({preview})
            })
    };

    store.addListener('activeItem', handleActiveItem)

    const handlePreview = ({preview}, {preview: _preview}) => {

        _preview && removePreview(_preview)
        preview && addPreview(preview)
    };

    store.addListener('preview', handlePreview)

    imagesLoaded(rootEl.QA('img'), () => {

        rootEl.Q('.loader').classList.add('hidden');
        rootEl.Q('.artists-list').classList.add('visible');
    })

    window.addEventListener('keyup', ({code}) =>
        store.state.preview
        && code === 'Escape'
        && close()
    );

    window.addEventListener('click', (e) =>
        store.state.preview
        && !isElement(e.target, store.state.preview)
        && !e.target.closest('.artist-item')
        && close()
    );

    rootEl.addEventListener('click', withSelectorCallback('.toggle-filter', (e, target) => {

        e.preventDefault();
        const el = rootEl.Q('.artist-filters .desktop');
        el.classList.toggle('active');
        target.innerText = el.classList.contains('active')
            ? 'Sjkul FILTER'
            : 'Vis FILTER';
    }));

    rootEl.addEventListener('click', withSelectorCallback('.artist-item', (e, activeItem) => {

        e.preventDefault();
        store.setState({activeItem})
    }));
}
