import {ui, updateModules} from "../components/moduleHandler";

import SimpleState from "../classes/simpleState";
import listEvents from "./userLists/listEvents";
import Login from "../reactComponents/Login";
import pushToLatest from "./pushToLatest";
import listCounter from "./userLists/listCounter";
import loginModal from "./loginModal";
import scrollTo from "./scrollTo";
import userListPanel from "./userLists/userListPanel";
import {modal} from "./modal";


const nav = token => {

    ui.pushToLatest = pushToLatest;
    ui.listCounter = listCounter;
    ui.loginModal = loginModal;
    ui.scrollTo = scrollTo;
    ui.userListPanel = userListPanel;

    // Hvorfor er denne her!?
    //updateModules();

    const {element} = token;
    const store = new SimpleState({
        showSecondary: false,
        showMobile: false
    });

    element.Q('.nav-overlay').addEventListener('click', () => {
        store.setState({
            showSecondary: false,
            showMobile: false,
        })
    })

    const btnClick = (e) => {

        const {name, value} = e.target;


        switch (name) {
            case 'search':

                break;

            case 'favorites':
                listEvents.emit('togglePanel', {activeTab: 'favorites'});
                break;

            case 'latest':
                listEvents.emit('togglePanel', {activeTab: 'latest'});
                break;

            case 'login':
                modal.setComponent({
                    Component: Login
                })
                    .open();
                break;

            case 'toggleSecondary':

                store.setState({
                    showSecondary: !store.state.showSecondary
                })

                break;
        }
    }

    store.change = function (prop, state, prevState) {

        switch (prop) {
            case 'showSecondary':

                if(state.showSecondary) {
                    element.AddClass('nav_showSecondary')
                } else {
                    element.RemoveClass('nav_showSecondary')
                }

                break;

            default:
                console.log(`prop "${prop}" unhandled`)
        }
    }

    toArray(document.querySelectorAll("button"))
        .forEach(el => el.addEventListener('click', btnClick))

    return token;
}

export default nav;