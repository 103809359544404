const filterOptions = {
  color: [
    {
      labelText: 'Sort',
      value: 'black',
    },
    {
      labelText: 'Hvit',
      value: 'white',
    },
    {
      labelText: 'Grå',
      value: 'grey',
    },
    {
      labelText: 'Brun',
      value: 'brown',
    },
    {
      labelText: 'Natur',
      value: 'nature',
    },
    {
      labelText: 'Sølv',
      value: 'silver',
    },
    {
      labelText: 'Gull',
      value: 'gold',
    },
    {
      labelText: 'Andre',
      value: 'other',
    },
  ],
  profile: [
    {
      labelText: 'Rett',
      value: 'flat',
    },
    {
      labelText: 'Skrående',
      value: 'sloped',
    },
    {
      labelText: 'Ornamentert',
      value: 'ornamented',
    },
    {
      labelText: 'Profilert',
      value: 'profiled',
    },
    {
      labelText: 'Buet/ avrundet',
      value: 'rounded',
    },
    {
      labelText: 'Andre',
      value: 'other',
    },
  ],
  width: [
    {
      labelText: 'Smal (opptil 20mm)',
      value: 'sm',
    },
    {
      labelText: 'Normal (20mm - 36mm)',
      value: 'md',
    },
    {
      labelText: 'Bred (36mm +)',
      value: 'lg',
    },
  ],
}

const colorMap = new Map( [
  ['sort', 'black'],
  ['grå', 'grey'],
  ['hvit', 'white'],
  ['brun', 'brown'],
  ['natur', 'nature'],
  ['gull', 'gold'],
  ['sølv', 'silver'],
  ['bronse', 'other'],
]);

const profileMap = new Map ([
  ['rett', 'flat'],
  ['skrående', 'sloped'],
  ['ornament', 'ornamented'],
  ['profil', 'profiled'],
  ['buet', 'rounded'],
  ['rund', 'rounded'],
])

const frameColor = name => {

  const match = name.match(/sort|grå|hvit|brun|natur|gull|sølv|bronse/i);

  return match &&
    colorMap.get(match[0].toLowerCase()) ||
    'other';
}

const frameWidth = w => {

  return w < 20 ? 'sm' :
         w < 36 ? 'md' :
         'lg';
}

const frameProfile = name => {

  const match = name.match(/rett|skrående|ornament|buet|rund|profil/i)

  return match &&
    profileMap.get(match[0].toLowerCase()) ||
    'other';
}

export const frameParser = f => {

  return {
    ...f,
    color: frameColor(f.name),
    width: frameWidth(f.w_mm),
    profile: frameProfile(f.name)
  }
}

export default filterOptions;