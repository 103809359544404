import Events from "./events";

var noop = function () { };

if(typeof environment == 'undefined') {
    var environment = {
        type: 'vendo'
    }
}

var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

var events = new Events(),
    _templates = {},
    stateManager = {
        popstateScrollY : null
    };

function toArray(o) {
    for (var a = [], l=o.length; l--;) a[l] = o[l];
    return a;
}

addEventListener('popstate', popstateHandler);

function popstateHandler() {
    stateManager.popstateScrollY = window.scrollY;
}

//addEventListener();

// Query ////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

var query = function (q, r) {

    r = r || document;

    return r.querySelector(q);
};

var queryAll = function (q, r) {

    r = r || document;
    return toArray(r.querySelectorAll(q));
};


var queryClass = function (q, r) {

    r = r || document;
    return toArray(r.getElementsByClassName(q));
};

var queryTag = function (q, r) {

    r = r || document;
    return toArray(r.getElementsByTagName(q));
};


// Element //////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

var createElem = function (tagName, attrs, vars) {

    if(tagName == 'fragment') {return document.createDocumentFragment()}

    vars = vars || {};
    var el = document.createElement(tagName);

    if(attrs) {

        for(var attr in attrs) {
            el.setAttribute(attr, attrs[attr]);
        }
    }
    return el;
};


// Element Prototype ////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

var EP = Element.prototype;

//todo: revisit!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


Object.defineProperty(EP, 'InnerHTML', {

    set: function (v) {

        this.innerHTML = v;
        events.fireEvent('innerhtml', {
            target: this,
            html: v
        })
    },

    get: function () {

        return this.innerHTML;
    }
});

EP.Cache =  function(storage, name){
    storage instanceof Array ? storage.push(this) : storage[name] = this;
    return this;
};

EP.Call = function(fn, thisArg) {

    fn.call(thisArg ? thisArg : this);
    return this;
};

EP.Append = function(el) {

    var args = arguments;

    if(typeof el == 'function') {
        el = el();
        args = el.length ? el : [el]
    }

    for(var i = 0; i < args.length; i++) {

        var arg = args[i];
        if(arg === null || arg === this.null) continue;

        arg = typeof arg == 'function' ? arg() : arg;

        if(arg instanceof Array) {

            for(var j = 0; j < arg.length; j++)  {
                this.appendChild(arg[j]);
            }
        }else {

            this.appendChild(arg);
        }

    }

    return this;
};

EP.InsertXHR = function(request, callback) {
    var t = this;
    xhr(request, {
        onDone: function(res) {
            t.InnerHTML = res;
            if(callback) callback(t);
        }
    });
    return this;
};

EP.AppendXHR = function(request, callback) {

    var t = this,
        temp = createElem('div'),
        children,
        fragment = document.createDocumentFragment();

    xhr(request, {

        onDone: function(res) {

            temp.innerHTML = res;
            children = toArray(temp.children);

            for(var i = 0; i < children.length; i++) {
                fragment.appendChild(children[i])
            }

            t.Append(fragment);

            if(callback) callback(t, children);
        }
    });
    return this;
};

EP.SetStyle = function (prop, val) {

    if(!val) {
        this.style.removeProperty(prop);
        return this
    }

    this.style[prop] = val;
    return this;
};

EP.AppendTo = function(target) {

    target.appendChild(this);
    return this;
};

EP.Prepend = function(el) {

    var args = arguments;

    if(typeof el == 'function') {
        el = el();
        args = el.length ? el : [el]
    }

    for(var i = args.length-1; i > -1; i--) {
        this.insertBefore(args[i], this.firstChild);
    }

    return this;
};

EP.PrependTo =function(target) {

    target.insertBefore(this, target.firstChild);
    return this;
};

EP.InsertBefore = function (target) {

    target.parentElement.insertBefore(this, target);
    return this;
};

EP.InsertAfter = function (target) {

    if(target.nextSibling) {
        target.parentElement.insertBefore(this, target.nextSibling);
    }else {
        target.parentElement.appendChild(this);
    }
    return this;
};

EP.Remove = function() {

    events.fireEvent('innerhtml', {
        target: this,
        parent: this.parentElement
    });

    this.remove();
    return this;
};

EP.Create = function (tagName, vars) {

    return createElem(tagName, vars);
};

EP.AddClass = function (c) {

    if(typeof c == 'string') { c = c.split(' ') }

    for(var i = 0; i < c.length; i++) {

        this.classList.add(c[i]);
    }
    return this
};

EP.RemoveClass = function (c) {

    if(typeof c == 'string') { c = c.split(' ') }

    for(var i = 0; i < c.length; i++) {

        this.classList.remove(c[i]);
    }
    return this
};

EP.ToggleClass = function (c) {

    this.classList.toggle(c);
    return this
};

EP.SetClass = function (v) {

    this.setAttribute('class', v);
    return this
};

EP.SetAttribute = function (attr, v) {

    this.setAttribute(attr, v);
    return this
};

EP.Html = function (v) {

    this.InnerHTML = v;
    return this
};

EP.Text = function (v) {

    this.textContent = v;
    return this
};

EP.Q = function (q) {

    return query.apply(null, [q, this])
};

EP.QA = function (q) {

    return queryAll.apply(null, [q, this])
};

EP.QC = function (q) {

    return queryClass.apply(null, [q, this])
};

EP.QT = function (q) {

    return queryTag.apply(null, [q, this])
};


// API //////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////





export const Q = query;
export const QA = queryAll;
export const QT = queryTag;
export const QC = queryClass;
export const C = createElem;

export const addListener = events.addListener.bind(events);
export const removeListener = events.removeListener.bind(events);
export const fireEvent = events.fireEvent.bind(events);
