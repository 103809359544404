import React from 'react';
import ListItem, {mapServerData as mapItem} from "../ListItem";

export default ({list, onDelete}) => (
    <div className='userList userList_latest'>

        {list.length &&
        list.map(
            (entry) => {
                const itemData = {
                    ...mapItem(entry),
                    isWishline: true,
                    onDelete,
                };

                return <ListItem modClassNames={['favList', 'wishLine']} {...itemData}/>
            }
        ) || null}

        {!list.length &&
        <div className="userList-placeholder">
            Du har ingen sist sette enda. Sjekk ut <a href='/galleri'>nettgalleriet</a> !
        </div> || null}
    </div>
);
