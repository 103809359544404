import { gsap } from "gsap";
import { Power2 } from "gsap";
import {C, Q} from "../Jesus";
import EppoScroll from "../eppoScroll";
import {withSelectorCallback} from "../functions";
import request from 'superagent';
import { debounce } from "lodash"

export default ({element: container, data: {url = null}}) => {

  const articleItemsEl = container.Q('#articles_list');

  const state = {
    url,
    loading: false,
    currentArticles: articleItemsEl.QA('.article'),
    currentWithinBounds: null,
  }

  const navItemsEl = container.Q('.articles-navItems');

  const getArticles = async (url) => {

    state.loading = true;
    gsap.to(navItemsEl, { duration: .3, opacity: .5});

    const res = await request.get(url)
      .then(r => r.text);

    state.loading = false;
    gsap.to(navItemsEl, { duration: .3, opacity: 1});

    return res;
  }

  const processArticleResponse = (res) => {

    const tmpDom = C('div').Html(res);
    const navItems = tmpDom.QA('.articles-navItem');
    const articleItems = tmpDom.QA('.article');
    const pager = tmpDom.Q('.pagination > a');

    return {
      navItems,
      articleItems,
      url: pager?.href,
    }
  }

  const loadMoreBtn = container.Q('#load-more-doc');
  const scroller = new EppoScroll(navItemsEl);

  const handleProcessed = ({navItems, articleItems, url}) => {

    state.url = url;

    !url && loadMoreBtn.Html('')
      .Append(C('h3').Text(loadMoreBtn.dataset.message))

    navItemsEl.Append(navItems);
    articleItemsEl.Append(articleItems);

    state.currentArticles = articleItemsEl.QA('.article');
    state.loading = false;
  }

  const handleGetMore = () => {

    state.url && !state.loading && getArticles(state.url)
      .then(processArticleResponse)
      .then(handleProcessed)
  }

  url && loadMoreBtn?.addEventListener('click', e => {
    e.preventDefault();
    handleGetMore();
  });

  const navScroll = container.Q('.articles-nav .scroll-inner');

  const scrollNavItemIntoView = debounce(() => {

    const target = Q('.articles-navItem_active');
    target && navItemOffBounds(target) && gsap.to(navScroll, { duration: .6,
      scrollTo: target,
      ease: Power2.easeInOut
    })
  }, 500);

  const handleNavScroll = e => {

    const isBottom = navScroll.scrollHeight < (navScroll.scrollTop + navScroll.clientHeight + 10);
    isBottom && handleGetMore();
  };

  url && navScroll.addEventListener('scroll', handleNavScroll);
  url && handleNavScroll();

  const articleWithinBounds = () => state.currentArticles
    .find(el => el.getBoundingClientRect().top > 10)

  const navItemOffBounds = (item) => {

    const {top, bottom} = item.getBoundingClientRect();

    return top < 0 || bottom > window.innerHeight;
  }

  // const

  const updateWithinBounds = (articleEl) => {

    state.currentWithinBounds = articleEl;

    navItemsEl.QA('.articles-navItem_active')
      .forEach(el => el.classList.remove('articles-navItem_active'))

    const navItem = navItemsEl.Q(`[data-container= '${articleEl.id}']`)?.closest('.articles-navItem');

    if (navItem){
      navItem.classList.add('articles-navItem_active');
      navItemOffBounds(navItem) && scrollNavItemIntoView();
    }
  }

  updateWithinBounds(articleWithinBounds());

  window.addEventListener('scroll', () => {

    const within = articleWithinBounds();

    within !== state.currentWithinBounds
    && updateWithinBounds(within);
  })

  const nav = container.querySelector('.articles-nav');

  Q('.articles-navToggle')?.addEventListener('click', e => {
    e.preventDefault();
    nav.classList.toggle('articles-nav_open');
  })

  new IntersectionObserver(
    ([e]) => e.target.classList.toggle('articles-nav_stuck', e.intersectionRatio >= 1),
    {threshold: [1]}
  )
    .observe(nav);

  nav.addEventListener('click', withSelectorCallback('.articles-navItem-link', (e, target) => {

    e.preventDefault();
    scrollToElement(Q(`#${target.dataset.container || '_nope_'}`))
  }))
};
