import React, {Component} from 'react';
import Panel from "./Panel/Panel";
import listEvents from "../modules/userLists/listEvents";
import UserLists from "./UserLists/UserLists";

export default class UserListContainer extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        isOpen: false,
        activeTab: sessionStorage.getItem('lastUserList')  || 'latest'
    };

    componentDidMount() {

        listEvents.addListener('togglePanel', (e)=> {
            this.setState({
                isOpen: !this.state.isOpen,
                activeTab: e.activeTab || this.state.activeTab
            })
        });
    }

    closePanel = () => {

        this.setState({
            isOpen: false
        })
    };

    handleTabClick = ({activeTab}) => {

        sessionStorage.setItem('lastUserList', activeTab);
        this.setState({
            activeTab
        })
    };

    render() {

        const {isOpen, activeTab} = this.state;

        return (
            <Panel className='Panel_userLists'
                   onClose={this.closePanel} {...{isOpen}}>
                <UserLists onTabClick={this.handleTabClick} onClose={this.closePanel} activeTab={activeTab}/>
            </Panel>
        )
    }
}
