import React, {render} from 'react';
import ModalContainer from "../reactComponents/ModalContainer";
import Events from 'eventemitter3';

const events = new Events;

export const modal = {
    events,
    close: () => {
        events.emit('close');
        return modal;
    },
    open: () => {
        events.emit('open');
        return modal;
    },
    toggle: () => {
        events.emit('toggle');
        return modal;
    },
    setComponent: (vars) => {
        events.emit('setComponent', vars);
        return modal;
    },
    setHtml: (vars) => {
        events.emit('setHtml', vars);
        return modal;
    },
}

let promiseResolver = (resolve, reject, value) => {
    return () => {
        resolve(value)
    }
}

let resolveApi;

export const asyncApi = new Promise(((resolve, reject) => {
    resolveApi = promiseResolver(resolve, reject, modal)
}))

export default ({element:rootElement, data = {}}) => {

    render(
        <ModalContainer events={events}/>,
        rootElement,
    )

    resolveApi();
};
