import { gsap } from "gsap";
import React, {useState, useEffect, useRef} from "react";
import request from 'superagent';
import {E} from "./HelperComponents";
import {modal} from '../modules/modal';
import Login from './Login';


const ForgotPassword = (props) => {

    const [input, setInput] = useState({
        username: '',
        password: ''
    })

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const url = '/ajax_login';

    const errorRef = useRef(null);

    useEffect(() => {

        if (!error || submitting) return;

        const tl = gsap.timeline();
        tl.set(errorRef.current, {opacity: 1})
            .to(errorRef.current, .3, {
                opacity: 0,
                repeat: 4,
                yoyo: true
            })
            .to(errorRef.current, .3, {opacity: 1})
    }, [submitting])

    const handleInput = ({target: {name, value}}) => {

        setInput({
            ...input,
            [name]: value
        })
    }

    const submit = async (extra = {}) => {

        if (submitting) return;

        if (!input.username) {
            setError('Du må fylle ut brukernavnet ditt');
            return;
        }

        setError(null);
        setSubmitting(true);

        const res = await request.post(url)
            .type('form')
            .send({
                ...input,
                ...extra,
            })
            .then(({body}) => body)
            .catch((err) => {
                const {redit, text} = err.response.body;
                setError(text);
                return;
            });

        setSubmitting(false);
        const {redir, text} = (res || {});

        !redir && setError((text || 'Ukjent feil oppstod'));
        redir && (document.location.href = redir.replace('Location: ', ''));

        if (error) return;

        modal.setComponent({
            Component: Login,
            props: {
		msg: text,
                username: input.username
            }
        }).open();
    }

    const handleClick = (e) => {
        e.preventDefault();
        const {target: {name}} = e;
        submit({[name]: 1})
    }

    const handleSubmit = (e, clicked = null) => {
        e.preventDefault();
        submit({login_x: 1})
    }

    const gotoLogin = (e) => {
        e.preventDefault();
        modal.setComponent({
            Component: Login
        }).open();
    }

    return (
        <div className="registration-container loginForm">
            <h2 className='loginForm-h text-3xl'><E>Send passord</E></h2>

            <p className="mb-4">
                <em dangerouslySetInnerHTML={
                    {
                        __html: `Hvis du har glemt passordet ditt, skriv inn ditt brukernavn og klikk "send passord"`
                    }
                }/>
            </p>

            {error &&
            <div ref={errorRef} className="feedback loginForm-error">{error}</div> || null}

            <form id="login-form" method="post" className="loginForm-form" onSubmit={handleSubmit}>
                <div className="loginForm-fields">
                    <fieldset>
                        <div className="field">
                            <label className='field-label' htmlFor="username">Brukernavn</label>
                            <input id="username"
                                   className='field-input'
                                   onInput={handleInput}
                                   type="text"
                                   value={input.username}
                                   name="username"/>
                        </div>
                    </fieldset>
                    <p style={{textAlign: 'right', fontWeight: 'bold', textDecoration: 'underline'}}>
                        <a href="#" onClick={gotoLogin}>Tilbake til innlogging</a>
                    </p>
                </div>
                <div className='btnGroup loginForm-actions'>
                    <button id="mailpass_x"
                            type="submit"
                            className="btn btn_primary"
                            value="1"
                            disabled={submitting}
                            onClick={handleClick}
                            name="mailpass_x">
                        Send passord
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ForgotPassword;
