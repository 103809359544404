import Events from 'eventemitter3';
import { difference } from "lodash"
const apiUrl = '/api/latest_by_id';
import request from 'superagent';

export const latestListEvents = new Events;

export const getFromStorage = () => {

    const stored = localStorage.getItem('latest');
    const parsed = stored && JSON.parse(stored) || [];

    latestListEvents.emit('count', parsed.length);

    return parsed;
};

export const pushToStorage = (item) => {

    const stored = getFromStorage()
        .filter(entry => !(item.type === entry.type && item.id === entry.id));

    const items = [item, ...stored]
        .slice(0, 10);

    localStorage.setItem('latest', JSON.stringify(items));

    latestListEvents.emit('count', items.length);

    return items;
};

const removeAnomalies = (query, result) => {

    const productAnomalies = difference(
        query.product_ids,
        result.map(entry => entry.product_id)
    )
        .map(id => ({
            id: id,
            type: 'product'
        }));

    productAnomalies.forEach(removeFromLatest);
};

export const getLatest = async () => {

    const query = getFromStorage()
        .reduce((ret, item) => {
            ret['product_ids[]'] = [item.id, ...ret['product_ids[]']];
            return ret;
        }, {'product_ids[]': []});

    const queryIds = query['product_ids[]'];

    if(!queryIds.length) return [];

    return await request(apiUrl)
        .query(query)
        .type('form')
        .then((res) => {

            const products = res.body.products.sort((a, b) => {
                return queryIds.indexOf(b.product_id) - queryIds.indexOf(a.product_id);
            });

            products.length !== queryIds.length && removeAnomalies(query, products);

            return products;
        })
};

export const removeFromLatest = (item = {type: 'product', id: 0}) => {

    const items = getFromStorage()
        .filter(entry => !(item.type === entry.type && item.id === entry.id));

    localStorage.setItem('latest', JSON.stringify(items));
    latestListEvents.emit('count', items.length);

    return items;
};

export const clearLatest = () => {
    localStorage.clearItem('latest');
    latestListEvents.emit('count', 0);
};
