import {thousandSeparate, withSelectorCallback} from "./functions";
import frameStore from "./modules/frameStore";
import {Q, C} from './Jesus';

const noop = () => undefined;
const priceFormat = p => thousandSeparate(p, ' ') + ',-';

export default (rootElement, {
    onSelectionClick = noop
} = {}) => {

    function renderSelectionItem({title = null, price = null, thumb = null, type = null, target = null}) {

        return C('div', {'class': `ctaBar-selection ctaBar-selection_${type}`}).Append(
            C('div', {'class': 'ctaBar-thumbWrap'}).Append(
                type !== 'glass' && C('img', {'class': 'ctaBar-selectionThumb', src: thumb}) || null
            ).Call(function () {
                (type === 'glass') && this.Html(`<svg class="ico ico_glass"><use xlink:href="#glass"></use></svg>`)
            }),
            C('div', {'class': 'ctaBar-selectionContent'}).Append(
                C('div', {'class': 'ctaBar-selectionTitle'}).Html(title),
                C('div', {'class': 'ctaBar-selectionPrice'}).Text(price)
            )
        )
            .Call(function () {
                this.addEventListener('click', function () {
                    onSelectionClick({type});
                })
            })
    }

    const variantEl = Q('.product_variant:checked');
    variantEl && Q('.variant_name').Text(Q('.product_variant:checked~label').innerText)

    var dom = {
        lead: rootElement.Q('.ctaBar-lead'),
        cta: rootElement.Q('.ctaBar-cta'),
        selections: rootElement.Q('.ctaBar-selections')
    };

    rootElement.addEventListener('change', withSelectorCallback('[data-behavior="toggle-form"]', (e, el) =>
        el.RemoveClass('active')
            .Q('.toggle')
            ?.Text(e.target.value)))

    var initialData = rootElement.dataset.ctaBar && JSON.parse(rootElement.dataset.ctaBar);
    var {type: barType} = initialData;

    if (barType !== 'auction' && barType !== 'framing') {

        const productSelectionItem = renderSelectionItem({
            price: `${priceFormat(initialData.basePrice)}`,
            title: initialData.productTitle,
            thumb: initialData.productThumb,
            type: 'product',
        });

        dom.selections.Html('').Append(productSelectionItem);
    }

    if (barType === 'framing') {
        onSelectionClick({type: 'frame'});
        frameStore.setState({awake: true});
    }

    if (barType === 'framing' || barType === 'workshop') {
        frameStore.addListener('framing_type', ({framing_type}) => {

            const {value} = framing_type;

            const inp = rootElement.Q('[name="framing_type"]');
            inp && (inp.value = value);
        })
    }

    const selectionBase =
        (barType === 'workshop' && ['product', 'frame', 'glass']) ||
        (barType === 'framing' && ['frame', 'glass']) ||
        (barType === 'product' && ['product']) ||
        [];

    return {
        type: barType,
        updateSelection: function (data, frameState) {

            const selectedElements = {
                frame: frameState.selected_frame,
                glass: data.activeGlass || null,
            };

            const selectionData = selectionBase
                .filter(type => {
                    return type === 'product' || !!selectedElements[type]
                })
                .map(type => {

                    switch (type) {

                        case 'product':
                            const {basePrice, priceText} = data.productForm;

                            return {
                                price: priceText || priceFormat(basePrice),
                                title: initialData.productTitle,
                                thumb: initialData.productThumb,
                                type,
                            };

                        case 'frame': {

                            const {framing_type: fType} = frameState;

                            const frame = selectedElements[type];
                            const isStuck = fType &&
                                (fType.value === 3 || fType.value === 4);

                            const framingPrice = fType && fType.price || 0;

                            return {
                                price: priceFormat(frame.price + framingPrice),
                                title: isStuck && '(Klebet) ' + frame.name || frame.name,
                                thumb: `${frameState.edoc_url_prefix}frame/${frame.frame_key}-detalj.jpg`,
                                type,
                            };
                        }

                        case 'glass': {
                            const el = selectedElements[type];
                            return {
                                price: priceFormat(JSON.parse(el.dataset.item).price),
                                title: el.Q('.title').innerHTML,
                                thumb: initialData.glassIcon,
                                type,
                            };
                        }
                    }
                });

            dom.selections.Html('').Append(selectionData.map(renderSelectionItem));
        }
    };
};