import React from 'react';
import classNames from 'classnames';
import SvgIcon from "./SvgIcon";

const noop = () => {};
const ListItem = (
    {
        type,
        href,
        imgSrc,
        proposition,
        propositionFormatted,
        creator,
        title,
        galleryPrice,
        timeLeft,
        isWishline,
        wishlineId,
        modClassNames = [],
        onDelete,
        onNavigate,
        itemId,
        productId,
    }
) => {

    const outerClassNames = classNames(
        'listItem',
        `listItem_${type}`,
        modClassNames.map(mod => `listItem_${mod}`),
    );

    const navigateData = !onNavigate ? null : {
        itemId,
        isWishline
    };

    const handleNavigate = !onNavigate ? noop : (e) => {
        onNavigate(e, navigateData);
    };

    return (
        <div className={outerClassNames} itemscope itemtype="http://schema.org/Product">
            <figure className="listItem-inner">
                <a className="listItem-imageWrap" href={href} onClick={handleNavigate}>
                    <img itemprop="image"
                         className="listItem-image"
                         src={imgSrc}
                         alt={`${title} av ${creator}`}/>
                </a>

                <figcaption className="listItem-info">
                    <div className="listItem-head">
                        <h3 className="listItem-h" itemprop="name">{title}</h3>
                        <div className="listItem-creator">{creator}</div>
                    </div>

                    <div className="listItem-details">

                        {galleryPrice &&
                        <div className="shopItem-detail shopItem-detail_auctionPrice">
                            <SvgIcon name='eye'/>{galleryPrice}
                        </div> || null}

                        {timeLeft &&
                        <div className="shopItem-detail shopItem-detail_timer">
                            <SvgIcon name='hammer'/>{timeLeft}
                        </div> || null}
                    </div>

                    <div className="listItem-cta" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
                        <meta itemprop="priceCurrency" content="NOK"/>
                        <span className="listItem-cta-proposition"
                              itemprop="price"
                              content={proposition}>
                            {propositionFormatted}
                        </span>

                        {onDelete &&
                        <button type='button'
                                className='listItem-btn_icoSquare'
                                data-wishline-id={wishlineId}
                                data-context='Favourites'
                                data-item-id={itemId}
                                data-product-id={productId}
                                onClick={onDelete}>
                            <SvgIcon name='cross'/>
                        </button> || null}

                        <a href={href} onClick={handleNavigate} className="listItem-cta-btn">Se mer</a>
                    </div>
                </figcaption>
            </figure>
        </div>
    )
};

export const mapServerData = (item) => {

    const propositionFormatted = item.price_inc.replace(',00', ',-');
    const proposition = parseInt(propositionFormatted.match(/\d+/g).join(''));

    return {
        proposition,
        propositionFormatted,
        productId: item.product_id,
        wishlineId: item.wishline_id,
        itemId: item.item_id,
        title: item.name,
        type: 'product',
        href: item.href,
        imgSrc: item.img,
        creator: item.brand_name,
        galleryPrice: '',
    }
};

export default ListItem;
