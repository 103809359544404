import React from "react";

export const E = ({children}) => {
  try {
    // If the string is UTF-8, this will work and not throw an error.
    return decodeURIComponent(escape(children));
  } catch (e) {
    // If it isn't, an error will be thrown, and we can assume that we have an ISO string.
    return children;
  }
}

export const Nada = () => null;
