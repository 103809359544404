import {C} from '../Jesus';
import countdown from "../vendor/countdown";

const auctionCountdown = (counterElement, {dateEnd: end}) => {

  const units = [
    ['dager', 'days'],
    ['timer', 'hours'],
    ['min', 'minutes'],
    ['sek', 'seconds']
  ];

  let counter = null;
  let counterStarted = false;

  counter = countdown(
    parseInt(end),
    updateCounter,
    countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS
  );

  function updateCounter(ts) {

    const relevance =
      !!ts.days ? 'days' :
        !!ts.hours ? 'hours' :
          'minutes';

    const timeIsUp = (ts.start - ts.end) <= 0;

    if (timeIsUp) {
      ts.days = 0;
      ts.minutes = 0;
      ts.hours = 0;
      ts.seconds = 0;

      counterStarted && location.reload();
      counter && window.clearTimeout(counter);

      if(counter) return;

      const submitBtn = document.querySelector('.ctaBar-submitBtn');
      const submitBtnText = submitBtn.querySelector('.ctaBar-btnText');
      const submitOptions = document.querySelector('.ctaDropdown-options');
      const submitOptionsToggle = document.querySelector('.ctaBar-cta .attachment');

      submitBtn.disabled = true;
      submitBtnText.innerText = 'AVSLUTTET';
      submitOptions.remove();
      submitOptionsToggle.remove();
    }

    counterStarted = counterStarted || !timeIsUp;

    counterElement.Html('')
      .Append(
        C('div', {'class': `countdownControl countdownControl_${relevance}Relevant`}).Append(
          units.map(function (unit) {
            return C('div', {'class': 'block'})
              .Text(unit[0])
              .Append(
                C('span')
                  .Text(
                    ('0' + ts[unit[1]].toString()).substring(1, 3)
                  )
              )
          })
        )
      );
  }
}

export default auctionCountdown;