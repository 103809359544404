import {Q} from '../Jesus';
import {isElementIn} from "../functions";

class Filter {

    constructor(
        {
            store,
            hiddenParams,
            url,
            filterUrl,
            rootElement,
            pager
        }
    ) {
        this.store = store;
        this.hiddenParams = hiddenParams || [];
        this.url = url;
        this.filterUrl = filterUrl;
        this.rootElement = rootElement;
        this.groups = this.rootElement.QA('.filter-group');
        this.bools = this.rootElement.QA('[type ="checkbox"]:not(.clear-filter-group), [type ="radio"]:not(.clear-filter-group)');
        this.clearGroupBools = this.rootElement.QA('.clear-filter-group');
        this.hiddenInputs = this.rootElement.QA('[type="hidden"]');
        this.pager = pager;

        this.selectionItems = [];
        this.queryStringKeys = {};
        this.valsObj = {};
        this.valsArr = [];
        this.elementsObj = {};

        this.hiddenParams.forEach((param) => {
            const [key, value] = param.split('=');
            this.queryStringKeys[key] = value;
        })

        window.addEventListener('click', this.clickHandler);
        window.addEventListener('popstate', this.popstateHandler);

        this.rootElement.addEventListener('change', (event) => {
            this.store.emit('change', {
                trigger:'onchange',
                target:event.target,
                event: event,
                config:this.vars
            });
        });

        this.groups.forEach((group) => {

            group.eppo = group.eppo || {};
            const clearGroupReference = group.querySelector('.clear-filter-group');

            if(clearGroupReference) {
                group.clearGroupReference = clearGroupReference;
            }

            const name = group.getAttribute('name');
            this.elementsObj[name] = {};

            group = group.QA('.filter-element');
            group.forEach((element) => {
                this.elementsObj[name][element.value] = element;
            });
        });

        this.setVals();
        this.updateFilterItems();
        this.updateSelectionItems();

        const state = {
            getItems: pager.navigate('get')
        };

        if(!state.getItems[pager.pParam]) {
            delete state.getItems[pager.pParam]
        }

        history.replaceState(state, document.title, window.location.pathname + state.getItems.url);
    }

    getGroupByName(name) {
        return this.groups
            .find(g => g.getAttribute('name') === name)
    }

    clickHandler = (e) => {
        const target = isElementIn(e.target, this.selectionItems);

        if (!target) return;

        target.filterRefenece.checked = false;
        this.store.emit('change', {
            trigger: 'removeSelectionItem',
            target: target.filterRefenece,
            event: e,
            config: this.vars
        })
    }

    popstateHandler = () => {
        if (!history.state) return;

        history.state.valsArr && (this.valsArr = history.state.valsArr);
        history.state.valsObj && (this.valsObj = history.state.valsObj);
        history.state.queryStringKeys && (this.queryStringKeys = history.state.queryStringKeys);
        this.queryStringKeys[this.pager.pParam] = this.pager.offset;

        this.updateFilterItems();
        this.updateSelectionItems();
        this.getItems && this.getItems();
    }

    updateFilterItems() {

        this.bools.forEach((bool) => bool.checked = false);

        for (var entry in this.valsObj) {

            if (!typeof this.valsObj[entry] !== 'object') {
                var checkAll;
                var group = this.getGroupByName(entry);

                if(!group) continue;

                if ((checkAll = group.getElementsByClassName('clear-filter-group')[0])) {
                    checkAll.checked = false;
                }

                for (var id in this.valsObj[entry]) {
                    this.elementsObj[entry][id].checked = true;
                }
            }
        }
    }

    updateSelectionItems() {

        this.selectionItems = [];

        if (this.selectionList) {
            this.selectionList.remove();
        }

        const listWrapper = this.selectionList = document.createElement('div');
        let list;

        listWrapper.AddClass('shop-sorter filterOutput').Append(
            list = document.createElement('div').AddClass('btnGroup filterOutput-btnGroup')
        );

        for (var i = 0; i < this.valsArr.length; i++) {

            const str = this.valsArr[i];
            const name = str.substring(0, str.indexOf('='));
            const value = str.substring(name.length + 1, str.length);

            const el = this.rootElement.querySelector('[name="' + name + '"][value="' + value + '"]')
                || this.rootElement.querySelector('[type="hidden"],[name="' + name + '"]');

            const text = el.dataset.text;

            let btn;
            list.appendChild(
                btn = document.createElement('a')
                    .AddClass('btn btn_neutral btn_sm')
            );

            btn.filterRefenece = el;
            btn.textContent = text + ' (X)';
            btn.href = 'javascript:void(0)';

            this.selectionItems.push(btn);
        }

        if (this.valsArr.length) {
            listWrapper.InsertAfter(Q('.shopHead'));
        }
    }

    submitFilter() {

        this.setVals();
        this.updateSelectionItems();
        this.toString();
        this.getItems(true);
    }

    setVals() {
        this.valsArr = [];
        this.valsObj = {};

        this.hiddenInputs.forEach((input) => {
            var name = input.name;
            var value = input.value;
            if (value) {
                this.valsObj[name] = value;
                this.valsArr.push(name + '=' + value);
            }

            console.log(input);
        });


        this.groups.forEach((group) => {
            var groupName = group.getAttribute('name');

            if (group.dataset.filterUi === 'booleans') {

                var bools = toArray(group.querySelectorAll(
                    '[type="checkBox"]:not(.clear-filter-group), [type="radio"]:not(.clear-filter-group)'
                ));

                this.valsObj[groupName] = {};

                bools.forEach((bool) => {
                    var boolName = bool.value;
                    if (bool.checked) {
                        this.valsObj[groupName][boolName] = true;
                        this.valsArr.push(groupName + '=' + boolName)
                    }
                });

                if (!Object.keys(this.valsObj[groupName]).length) {
                    delete this.valsObj[groupName];
                }
            }
        });
    }

    toQueryString(omitArr) {

        var s = this.toString(omitArr),
            keys = this.queryStringKeys;

        s == '' ? s = '?' : s += '&';

        !omitArr ? omitArr = [] : false;

        for (var key in keys) {
            if (!keys.hasOwnProperty(key) || omitArr.indexOf(key) > -1) continue;

            if (keys[key])
                s += (key + '=' + keys[key]) + '&';
        }
        s.endsWith('&') ? s = s.substring(0, s.length - 1) : false;

        return s;

    }

    toString(omitArr) {

        var o = this.valsObj,
            string = '?',
            i = 0,
            j;

        //types:
        var typesLen = Object.keys(o).length;
        for (var type in o) {
            i++;
            if (!o.hasOwnProperty(type) || Object.keys(o[type]).length == 0) {
                continue
            }
            if (omitArr) {
                if (omitArr.indexOf(type) > -1) {
                    continue
                }
            }
            string += type + '=';
            if (typeof o[type] != 'object') {
                string += [o[type]];
                i != typesLen ? string += '&' : false;
                continue;
            } else {
                //values:
                var valueLen = Object.keys(o[type]).length;
                j = 0;
                for (var val in o[type]) {
                    j++;
                    if (!o[type].hasOwnProperty(val)) {
                        continue
                    }
                    string += val;
                    j != valueLen ? string += ',' : false;
                }
            }
            i != typesLen ? string += '&' : false;
        }

        string === '?' ? string = '' : false;

        return string;
    }

    removeSelectionItem(item, byClick) {
        this.getItems(true);
    }

    getItems(pushHistory) {
        var query = this.toQueryString();

        if (this.lastItemPost !== query) {

            this.store.setState({
                query: {
                    clientQuery: this.toString(),
                    query: this.toQueryString()
                }
            });

            this.lastItemPost = query;
            pushHistory && this.pushHistory(this.toString());
        }
    }

    getState(str) {
        return {
            filter: true,
            url: window.location.pathname + str,
            search: str,
            queryStringKeys: this.queryStringKeys,
            valsObj: this.valsObj,
            valsArr: this.valsArr
        };
    }

    pushHistory(str) {
        history.pushState(this.getState(str), document.title, window.location.pathname + str);
    }

    replaceHistory(str) {
        history.replaceState(this.getState(str), document.title, window.location.pathname + str);
    }
}

export default Filter;
