import request from 'superagent';
import {C} from "../Jesus";

let req;

export default async (url) => {

    req && req.abort && req.abort();
    req = request(url)
        .query({'_': Date.now()});

    const html = await req
        .then(res => res.text)
        .catch(() => null);

    if(!html) return {};

    const tmpDom = C('div').Html(html);

    const loadingData = {
        itemsPerPage: 50,
        itemsTotal: null,
        doneMsg: 'Ingen flere objekter',
        ...(tmpDom.Q('#loading-wrapper')?.dataset || {}),
        ...(tmpDom.Q('.pagination .next')?.dataset || {})
    };

    return {
        images: tmpDom.QA('.shopItem-image'),
        items: tmpDom.QA('.shopItem'),
        nextUrl: tmpDom.Q('.pagination .next')?.getAttribute('href')
            || loadingData.url
            || null,
        ...{
            perPage: parseInt(loadingData.itemsPerPage),
            total: parseInt(loadingData.itemsTotal || '0'),
            doneMsg: loadingData.doneMsg
        }
    }
}