/**
 * Created by havard on 15.02.2018.
 */

const requestAnimationFrame = window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    function (fn) {
        return window.setTimeout(fn, 20);
    };

var listeners = [];

function listen() {
    for (var i = 0; i < listeners.length; i++) {
        var o = listeners[i];
        var el = o.el;
        if (el.offsetHeight !== o.lastHeight || el.offsetWidth !== o.lastWidth) {
            o.lastHeight = el.offsetHeight;
            o.lastWidth = el.offsetWidth;
            o.cb();
        }
    }
    if (listeners.length) {
        requestAnimationFrame(listen)
    }
}

export default {
    add: function (o) {
        o.lastHeight = o.el.offsetHeight;
        o.lastWidth = o.el.offsetWidth;
        listeners.push(o);
        if (listeners.length === 1) {
            requestAnimationFrame(listen);
        }
        return o;
    },
    remove: function (o) {
        //todo: test - fix
        for (var i = 0; i < listeners.length; i++) {
            var listener = listeners[i];
            if (o.el === listener.el) {
                listeners.splice(i, 1);
                break;
            }
        }
    }
};