import { gsap } from "gsap";
import Lazy from 'vanilla-lazyload';
import imagesLoaded from 'imagesloaded';
import EventedState from "../classes/eventedState";
import shopItemsLayout from "../components/shopItemsLayout";
import getItems from "../components/getItems";

export default ({element:rootEl, data:{nextUrl, msgSelector, doneMsg}}) => {

    const store = new EventedState({
        loading: null,
        nextUrl: null
    })

    const msgEl = document.querySelector(msgSelector) || document.createElement('div');
    const loaderEl = rootEl.querySelector('.loader');
    const container = rootEl.querySelector('.shopItems');
    const layout = shopItemsLayout(container);
    const lazyImages = new Lazy({
        elements_selector: ".shopItem-image_idle",
        callback_loaded: el => el.classList.remove('shopItem-image_idle')
    });

    const getMore = async url => {

        store.setState({loading: true});

        return getItems(url);
    };

    const scrollPassedThreshold = () =>
        container.getBoundingClientRect().bottom - window.innerHeight < 100;

    const handleScroll= () => scrollPassedThreshold() &&
        getMore(store.state.nextUrl)
            .then(handleProcessed);

    const handleProcessed = ({images, nextUrl, items}) => {

        if(!items?.length) {
            return store.setState({
                loading: false,
                nextUrl: null,
            })
        }

        gsap.set(items, {visibility: 'hidden'})

        layout.once( 'arrangeComplete', () => {

            gsap.set(items, {clearProps: 'visibility'})

            store.setState({
                loading: false,
                nextUrl,
            })
        });

        imagesLoaded(images, () => {
            layout.insert(items);
            lazyImages.update();
        });
    }

    store.addListener('loading', ({loading, nextUrl}) => {
        loading && window.removeEventListener('scroll', handleScroll);
    })

    store.addListener('nextUrl', ({nextUrl}) => {

        nextUrl && window.addEventListener('scroll', handleScroll);
        !nextUrl && msgEl.Html(doneMsg);
    })

    store.setState({
        loading: false,
        nextUrl
    })

    loaderEl && loaderEl.remove();
    layout.layout();
};
