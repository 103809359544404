import React, {useEffect, useState} from "react";
import Events from 'eventemitter3';
import SimpleState from "./simpleState";

class EventedState extends SimpleState {

    constructor(...args) {
        super(...args);
        this.events = new Events;
    }

    change(prop, state, prevState) {
        this.events.emit(prop, state, prevState)
    }

    addListener(event, fn) {
        this.events.addListener(event, fn)
    }

    emit(event, ...args) {
        this.events.emit(event, ...args)
    }

    once(event, fn) {
        this.events.once(event, fn)
    }

    removeListener(event, fn) {
        this.events.removeListener(event, fn)
    }
}

export default EventedState;

export const connect = (store, props = []) => {

    return (Component) => {

        const stateConnections = props.map((prop) => {
            const [value, setValue] = useState(store.state[prop])

            const storeSetter = (state) => {
                setValue(state[prop])
            };

            return {
                setterFn: storeSetter,
                value,
                prop,
            }
        })

        useEffect(() => {
            stateConnections.forEach(con => {
                store.addListener(con.prop, con.setterFn)
            })

            return () => {
                stateConnections.forEach(con => {
                    store.removeListener(con.prop, con.setterFn)
                })
            }
        }, [])

        const connectedProps = stateConnections.reduce((accum, con) => {
            accum[con.prop] = con.value;
            return accum;
        }, {})

        return (props) => <Component {...{...connectedProps, ...props}}/>
    }
};
