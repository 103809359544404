import { gsap } from "gsap";
import { Power1 } from "gsap";
import { Power2 } from "gsap";
import { Power3 } from "gsap";
import { Draggable } from "gsap/Draggable";
import envData from "./workshopEnvironmentsData";
import imagesLoaded from 'imagesloaded';
import {Q, C} from './Jesus';
import {isElementIn} from "./functions";

gsap.registerPlugin(Draggable);

export default ({rulers}) => {

    var frameContainer = Q('.frame-container'),
        posNull = frameContainer.parentElement,
        frame = Q('#custom-frame'),
        envTriggersList = Q('.surroundings'),
        workShop = Q('.workshop'),
        envTriggers = envTriggersList.QC('surrounding'),
        frameData = frame.dataset,
        image = frame.Q('img'),
        actionBar = Q('.action-bar'),
        outerWrapper = Q('.image-wrapper'),

        t = {
            envData: envData,
            envTriggers: envTriggers,
            frame: frame,
            actionBar: actionBar,
            image: image
        };

    t.setActive = function (trigger) {
        var id = trigger && parseInt(trigger.dataset.id);
        this.active = {
            trigger: trigger || null,
            id: id || null,
            env: this.envData.find(function (env) {
                return env.id === id;
            }, this)
        }
    };

    function init() {

        t.setActive(null);
        try {
            t.draggable = Draggable.create(frameContainer, {
                dragClickables: true,
                zIndexBoost: false
            })[0];
        } catch (err) {
            // Ignore Draggable error
        }
        t.draggable && t.draggable.disable();
        t.boundClickHandler = t.clickHandler.bind(t);
        workShop.addEventListener('click', t.boundClickHandler);

        return t;
    }

    t.sizeRoom = function (roomSize) {

        roomSize = roomSize || this.getRoomSize();

        gsap.set(outerWrapper, {
            height: outerWrapper.clientHeight
        });

        gsap.to(outerWrapper, { duration: .3,
            height: roomSize.h,
        });

        return roomSize;
    };

    t.unsizeRoom = function () {

        var fromHeight = outerWrapper.clientHeight,
            tl = gsap.timeline();

        tl.add('start')

            .set(outerWrapper, {
                clearProps: 'transform,height'
            })
            .add(function () {
                workShop.removeAttribute('id')
            })
            .from(outerWrapper, .2, {
                height: fromHeight,
                clearProps: 'height',
                force3D: true
            })
            .add(function () {
                outerWrapper.AddClass('animating');
            })
            .to(frameContainer, .2, {
                x: 0,
                y: 0,
                ease: Power3.ease
            }, '-=.2')
            .set(frameContainer, {
                clearProps: 'transform,position,left,top'
            })
            .to(posNull, .2, {
                y: 0,
                force3D: true
            }, '-=.2')
            .set(frameContainer, {
                scale: t.artScale
            })
            .to(frameContainer, .5, {
                scale: 1,
                ease: Power2.easeInOut,
                force3D: true
            })
            .add(function () {
                outerWrapper.RemoveClass('animating');
                t.draggable && t.draggable.disable();
            })
    };

    t.getRoomSize = function (room) {

        room = room || this.active.env.room;

        var factor = outerWrapper.clientWidth/room.w;

        var h = this.active.env.room.h = room.h;
        var w = this.active.env.room.w = room.w;

        return {
            factor: factor,
            h: h * factor,
            w: w * factor,
        };
    };

    t.clickHandler = function (e) {

        var target,
            targetLabel;

        if ((target = isElementIn(e.target, t.envTriggers, 10))) {
            targetLabel = 'envTrigger'
        }

        switch (targetLabel) {

            case 'envTrigger':
                var active = this.active;

                active.trigger &&
                    active.trigger === target && this.deactivate() || //trigger is active trigger -> deactivate()
                    active.trigger && this.activate({target: target}) || //or trigger is different from active trigger -> activate()
                    this.activate({target: target, firstInteraction: true}); // else trigger is first interaction -> activate(firstInteraction)

                break;
        }
    };

    t.activate = function (o) {

        var first = this.isFirstInteraction = o.firstInteraction || false;
        first && (image.style.width = image.clientWidth + 'px');
        first && (workShop.id = 'env-active');

        this.active.trigger && this.active.trigger.RemoveClass('active');
        this.setActive(o.target);
        this.active.trigger.AddClass('active');
        this.open();

        return this;
    };

    t.deactivate = function () {

        this.close();
        this.active.trigger.RemoveClass('active');
        this.setActive(null);
        return true;
    };

    t.open = function () {

        switch (t.active.env.type) {

            case 'big':
                this.createRoom();
                break;
        }
    };

    t.close = function () {

        switch (t.active.env.type) {

            case 'big':

                this.removeRoom();
                this.unsizeRoom();

                break;
        }
    };

    t.createRoom = function () {

        this.room && this.removeRoom();

        var env = this.active.env,
            tl = gsap.timeline(),
            roomScale;

        outerWrapper.AddClass('animating');

        rulers.size.y > 200 && (roomScale = 1.4) ||
        rulers.size.y > 150 && (roomScale = 1.3) ||
        rulers.size.y > 130 && (roomScale = 1.2) ||
        rulers.size.y > 100 && (roomScale = 1.1) ||
        rulers.size.y > 80 && (roomScale = 1) ||
        rulers.size.y > 65 && (roomScale = 1) ||
        rulers.size.y > 45 && (roomScale =.9) ||
        rulers.size.y > 35 && (roomScale =.8) ||
        rulers.size.y > 30 && (roomScale =.7) ||
            (roomScale =.65);

        var roomSize = Object.assign({}, env.room, {
                h: 185 * roomScale,
                w: 460 * roomScale
            });


        if(window.innerWidth < 701) {
            roomSize.w = roomSize.w * .5;
        }

        var pxRoomSize = this.getRoomSize(roomSize),
            pushAside = Math.max(1, roomScale),
            factor = env.factor = pxRoomSize.factor,
            room = this.room = C('div', {'class': 'workshop-room'}),
            elements = this.envElements = env.elements.map(function (el) {

                var ret = C('img', {src: el.src});
                ret.envData = el;

                gsap.set(ret, {

                    width: el.w * factor,
                    x: ((roomSize.w / 2) - (el.w / 2) + el.x * pushAside) * factor, // offset from center
                    y: typeof el.y === 'object' &&
                            'fromBottom' in el.y &&
                            (roomSize.h -  el.h + el.y.fromBottom) * factor ||
                        el.y * factor
                });
                return ret;
            });

        if(this.isFirstInteraction) {

            var artWidth = frameData.artWidth,
                artWidthPx = artWidth * factor,
                nowWidth = image.clientWidth,
                artScale = t.artScale = artWidthPx/nowWidth,
                createTl = gsap.timeline();

            window.scrollY &&
            createTl.to(window, .28, {
                scrollTo: {y: 0},
                ease: Power1.easeInOut
            }, .3);

            createTl.add(function () {
                t.sizeRoom(pxRoomSize)
            })
                .add(t.positionArt, '+=.1')
                .to(frameContainer, .6, {
                    scale: artScale,
                    force3D: true,
                    ease: Power1.easeInOut
                })
                .add(t.assembleRoom)
                .add(function () {
                    t.draggable && t.draggable.enable();
                });
        }else {

            this.sizeRoom(pxRoomSize);
            t.positionArt();
            t.assembleRoom();
        }
    };

    t.assembleRoom = function(room, elements) {

        //warning: gets called with different this.. use t as reference
        var tl = gsap.timeline();

        room = room || t.room;
        elements = elements || t.envElements;

        room.PrependTo(outerWrapper);

        imagesLoaded(elements, function () {

            room.Append(elements);

            tl.staggerFrom(elements, .28, {
                yPercent: function (x, t) {
                    var d = t.envData;
                    return d.in === 'down' && -30 ||
                        d.in === 'up' && 30 ||
                        0;
                },
                xPercent: function (x, t) {
                    var d = t.envData;
                    return d.in === 'right' && -30 ||
                        d.in === 'left' && 30 ||
                        0;
                },
                opacity: 0,
                ease: Power2.easeInOut
            }, .11)
                .add(function () {
                    outerWrapper.RemoveClass('animating')
                })
                .add(t.positionArt)
        });
    };

    t.removeRoom = function () {

        var t = this,
            tl = gsap.timeline(),
            room = this.room;

        tl.staggerTo(this.envElements, .28, {
            yPercent: function (x, t) {
                var d = t.envData;
                return d.out === 'down' && 50 ||
                    d.out === 'up' && -50 ||
                    0;

            },
            xPercent: function (x, t) {
                var d = t.envData;
                return d.out === 'right' && 50 ||
                    d.out === 'left' && -50 ||
                    0;
            },
            opacity: 0,
            ease: Power2.easeOut
        }, .11)
            .add(function () {
                room.remove()
            })
    };

    t.positionArt = function () {

        //warning: gets called with different this.. use t as reference

        var env = t.active.env,
            factor = env.factor,
            nullTop = posNull.offsetTop,
            ph = {}; // let ph be physical reference object

        ph.nullTop = -nullTop/factor;
        ph.nullBottom = ph.nullTop + env.room.h;
        ph.minTop = ph.nullBottom - (env.bounds.bottom + rulers.size.y);
        ph.wanted = ph.nullBottom - env.room.h/2 - (rulers.size.y * .62);
        ph.final = Math.min (
            ph.minTop,
            ph.wanted
        );

        gsap.set(frameContainer, {
            transformOrigin: '50% 0'
        });

        gsap.to(posNull, { duration: .3,
            y: ph.final * factor
        });
    };

    return init();
};
