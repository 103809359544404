import React from "react";

const formatPrice = (price) => {
  return `${price.replace(',00', ',-')}`
};

const Item = ({name, pname, img, href, price_inc, brand_name}) => (
  <div className="uAdded-item">
    <div className="uAdded-item-image">
      <img alt={name} src={img}/>
    </div>
    <div className="uAdded-item-details">
      <h3 className="uAdded-item-h">
        <a href={href}>{pname}</a>
      </h3>
      <p className="uAdded-item-author">{brand_name}</p>
      <p className="uAdded-item-price">{formatPrice(price_inc)}</p>
    </div>
  </div>
)

const SubItem = ({name, price_inc}) => (
  <div className="uAdded-item uAdded-item_sub">
    <div className="uAdded-item-details">
      <p className="uAdded-item-author">{name}</p>
      <p className="uAdded-item-price">{formatPrice(price_inc)}</p>
    </div>
  </div>
)

const CartItems = ({item, frame, glass, framing, onClose}) => (
  <div className={'uAdded uAdded_cart'}>
    <div className={'uAdded-header'}>
      <h1 className='uAdded-h'>Lagt til i handlekurv!</h1>
    </div>
    <div className="uAdded-items">
      {item && <Item {...item}/> || null}
      {frame && <SubItem {...frame}/> || null}
      {framing && <SubItem {...framing}/> || null}
      {glass && <SubItem {...glass}/> || null}
      <div className="uAdded-buttons">
        <a href="/handlekurv" className="btn btn_primary btn_lg">Gå til handlekurv</a>
      </div>
    </div>
  </div>
)

export default (props) => {

  const {item, frame, glass} = props;

  return (
    (item || frame || glass)
    && <CartItems {...props}/>
    || <div>Noe gikk galt, gitt! Kanskje ikke mer på lager?</div>
  )
}