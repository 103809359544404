var Events = function(thisArg) {
    this.t = thisArg;
    this.handlers = {
        //'someEvent': [fn, fn, fn]
    };
    this.counter = 0;
};


Events.prototype = {
    constructor: Events,
    addListener: function(eventLabel, fn) {
        var id = 'fn'+(this.counter ++);
        if(!this.handlers[eventLabel]) {
            this.handlers[eventLabel] = [];
        }
        this.handlers[eventLabel].push(fn);
        return fn
    },
    removeListener:function (eventLabel, fn) {
        var arr = this.handlers[eventLabel];
        var index = arr.indexOf(fn);
        index > -1 ? arr.splice(index, 1): false;
    },
    fireEvent: function (eventLabel, vars, thisArg) {
        var scope = thisArg || window;
        for(var fn in this.handlers[eventLabel]) {
            if(!this.handlers[eventLabel].hasOwnProperty(fn)) continue;
            this.handlers[eventLabel][fn].call(scope, vars)
        }
    }
};

export default Events;