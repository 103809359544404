import React, {render} from 'react';
import UserListContainer from "../../reactComponents/UserListContainer";

export default ({element:rootElement, data = {}}) => {

    render(
       <UserListContainer {...{data}}/>,
       rootElement
    )
};
