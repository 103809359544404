export default class SimpleState {

    constructor (state= {}) {
        this.state = state
    }

    setState (state) {
        Object.keys(state).forEach(prop => {
            if(!this.state[prop] || this.state[prop] !== state[prop]) {
                this.updateState(state[prop], prop);
            }
        })

        return this;
    }

    getState () {
        return this.state;
    }

    updateState (propState, prop) {

        const prevState = {...this.state};

        const nextState = {
            ...this.state,
            [prop]: propState
        };

        if(!this.shouldUpdate(prop, nextState, this.state)) {
            return false;
        }

        this.willChange(prop, nextState, this.state);
        this.state = nextState;
        this.change(prop, nextState, prevState);
    }

    shouldUpdate (prop, nextState, prevState) {
        return true;
    }

    willChange (prop, nextState, prevState) {

    }

    change (prop, state, prevState) {

    }
}
