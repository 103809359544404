import { gsap } from "gsap";
import {C} from '../Jesus';
import {withSelectorCallback} from "../functions";
import imagesLoaded from 'imagesloaded';

export default (trigger) => {

    const state = {
        active: null
    }

    const open = (src) => {
        state.active = render(src);
        document.body.appendChild(state.active);
        window.addEventListener('click', handleCloseClick);

        const lightbox = state.active.Q('.lightbox');
        const img = lightbox.Q('img');

        imagesLoaded(img, () => gsap.set(lightbox, {
            height: img.height,
            width: img.width,
        }))

        return true;
    }

    const close = (e, target) => {

        state.active.remove();
        state.active = false;
        window.removeEventListener('click', handleCloseClick);
        return true;
    }

    const render = (src) => C('div', {'class': 'overlay'}).Html(
        `<div class="lightbox">
            <img src="${src}" />
            <span class="close">
                <svg class="ico ico_cross">
                  <use xlink:href="#cross"></use>
                </svg>
            </span>
        </div>`
    )

    trigger.addEventListener('click', ({target}) => {
        open(target.dataset.src);
    })

    const handleCloseClick = withSelectorCallback('.close, .overlay', close);
}
