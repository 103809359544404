import frameStore from "./modules/frameStore";
import {elementWithinClass, thousandSeparate} from "./functions";
import {C, Q, QC} from './Jesus';
import Frame from './workshopFrame';
import workshopRulers from "./workshopRulers";
import workshopActionbar from "./workshopActionbar";
import workshopEnvironment from "./workshopEnvironment";
import initCtaBar from './ctaBar';
import framePreview from "./components/framePreview";

export default (
    {
        root: workshopEl
    }
) => {

    const {tabsData, destroy:destroyActionBar} = workshopActionbar();
    const ctaBarElement = Q('.ctaBar ');
    const ctaBar = initCtaBar(ctaBarElement, {
        onSelectionClick: ({type}) => {
            switch (type) {
                case 'frame':
                case 'glass':
                    var entry = tabsData.find(o => o.panel.classList.contains(type));
                    entry.button.click();
                    break;
            }
        }
    });

    const frameRoot = Q('.cFrame');
    const rulers = workshopRulers(frameRoot);
    const env = workshopEnvironment({rulers});
    const frame = new Frame(frameRoot, {rulers});

    const actionBarEl = Q('.action-bar');
    const initialFrameData = {...frame.data};

    const pForm = Q('#product_form') || Q('.buy-button form');
    const nada = C('input', {type: 'text'}).AppendTo(C('div'));
    const glassTrigger = Q('.glass-toggle.selected');
    const glassOption = actionBarEl.Q('.glass-selector') && actionBarEl.Q('.glass-selector').parentElement || nada;
    const fullFrameBtn = Q('.cFrame-previewTrigger');

    fullFrameBtn && framePreview(fullFrameBtn);

    const productForm = {
        glassPrice: null,
        glassPriceAlt: null,
        framePrice: null,
        framePriceAlt: null,
        framingPrice: 0,
        framingPriceAlt: 0,
        form: pForm,
        product_id: pForm.product_id,
        frame_id: pForm.frame_id,
        item_id: pForm.item_id,
        glass_id: pForm.glass_id,
        initialDataElement: Q('#pItem-data') || Q('#output-price') || nada, //todo: refactor, only used to get initial data
        totalPriceOutputs: QC('js_outputPrice'),
        framePriceOutputs: QC('js_outputFrame'),
        glassPriceOutputs: QC('js_outputGlass'),
        priceWrappersAlt: QC('js_priceWrapperAlt'),
        totalPriceOutputsAlt: QC('js_outputPriceAlt'),
        framePriceOutputsAlt: QC('js_outputFrameAlt'),
        glassPriceOutputsAlt: QC('js_outputGlassAlt'),
        savingsOutputs: QC('js_outputSavings'),
        frameDescriptionOutput: Q('.product-form-frame-info') || nada,
    };

    let initialItemData;

    if (typeof productForm.initialDataElement.dataset.item !== 'undefined') {
        initialItemData = JSON.parse(productForm.initialDataElement.dataset.item);

        productForm.basePrice = parseFloat(initialItemData.price);
        productForm.basePriceAlt = parseFloat(initialItemData.alt_price);
        productForm.priceText = initialItemData.price_text || null;
    } else {
        productForm.basePrice = 0;
        productForm.basePriceAlt = 0;
    }

    const t = {
        activeGlass: null,
        activeFrame: null,
        productForm: productForm
    };

    const priceFormat = p => thousandSeparate(p, ' ') + ',-';

    const sum = (a, b) => a + b || 0;

    t.updateProductForm = function () {

        var f = t.productForm;

        if (typeof f.glassPrice === 'number') {

            f.glassPriceOutputs.forEach(el => el.parentElement.SetStyle('display', null));
            f.glassPriceOutputsAlt.forEach(el => el.parentElement.SetStyle('display', null));
            f.glassPriceOutputs.forEach(el => el.innerText = priceFormat(f.glassPrice));
            f.glassPriceOutputsAlt.forEach(el => el.innerText = priceFormat(f.glassPriceAlt));

        } else {

            f.glassPriceOutputs.forEach(el => el.parentElement.SetStyle('display', 'none'));
            f.glassPriceOutputsAlt.forEach(el => el.parentElement.SetStyle('display', 'none'));
            f.glassPriceOutputs.forEach(el => el.innerText = '');
            f.glassPriceOutputsAlt.forEach(el => el.innerText = '');
        }

        if (typeof f.framePrice === 'number') {

            f.framePriceOutputs.forEach(el => el.parentElement.SetStyle('display', null));
            f.framePriceOutputsAlt.forEach(el => el.parentElement.SetStyle('display', null));
            f.framePriceOutputs.forEach(el => el.innerText = priceFormat(f.framePrice));
            f.framePriceOutputsAlt.forEach(el => el.innerText = priceFormat(f.framePriceAlt));

        } else {

            f.framePriceOutputs.forEach(el => el.parentElement.SetStyle('display', 'none'));
            f.framePriceOutputsAlt.forEach(el => el.parentElement.SetStyle('display', 'none'));
            f.framePriceOutputs.forEach(el => el.innerText = '');
            f.framePriceOutputsAlt.forEach(el => el.innerText = '');
        }

        var tot = [
            f.basePrice,
            f.framePrice,
            f.glassPrice,
            f.framingPrice
        ].reduce(sum, 0);

        var totAlt = [
            f.basePriceAlt,
            f.framePriceAlt,
            f.glassPriceAlt,
            f.framingPriceAlt
        ].reduce(sum, 0);

        f.totalPriceOutputs.forEach(el => el.innerText = priceFormat(tot));
        f.totalPriceOutputsAlt.forEach(el => el.innerText = priceFormat(totAlt));

        if (tot - totAlt) {
            f.priceWrappersAlt.forEach(el => el.SetStyle('display', null));
            f.savingsOutputs.forEach(el => el.innerText = priceFormat(Math.abs(tot - totAlt)));
        } else {
            f.priceWrappersAlt.forEach(el => el.SetStyle('display', 'none'));
            f.savingsOutputs.forEach(el => el.innerText = "");
        }

        ctaBar.updateSelection(t, frameStore.state);
    };

    t.init = function () {

        workshopEl.addEventListener('click', t.clickHandler);
        // firstFrameTrigger && t.handleFrameTrigger(firstFrameTrigger);
        glassTrigger && t.handleGlassTrigger(glassTrigger);
        t.updateProductForm();
        var loader = Q('.loader');
        loader && window.hideLoader(loader);

        return t;
    };

    t.destroy = function () {

        destroyActionBar();
    };

    t.clickHandler = function (e) {

        var target;

        if ((target = elementWithinClass(e.target, 'glass-toggle'))) {
            t.handleGlassTrigger(target);
            t.updateProductForm();
        }
    };

    const resetFrame = () => {
        t.productForm.glass_id.value = '';
        t.productForm.glassPrice = null;
        t.productForm.glassPriceAlt = null;

        t.productForm.frame_id.value = '';
        t.productForm.framePrice = null;
        t.productForm.framePriceAlt = null;

        productForm.frameDescriptionOutput.innerHTML = '';

        glassOption.SetStyle('display', 'none');
        frame.frame.AddClass('no-frame');
        frame.updateData();

        t.activeGlass && t.activeGlass.RemoveClass('selected');
        t.activeGlass = null;
    }

    frameStore.addListener('framing_type', ({framing_type}) => {

        frame.updateData({
            dim: framing_type.dim,
            framingType: framing_type.value,
            frameGap: framing_type.frame_gap_mm / 10,
            subjectGap: framing_type.subject_gap_mm / 10
        });

        t.productForm.framingPrice = framing_type.price;
        t.productForm.framingPriceAlt = framing_type.alt_price;

        ctaBar.updateSelection(t, frameStore.state);
        t.updateProductForm();
    })

    const handleSelectedFrame = (state) => {

        const {
            selected_frame: selected,
            edoc_url_prefix,
            framing_type = null,
        } = state;

        if (!selected) {
            resetFrame();
            t.updateProductForm();
            return;
        }

        frame.frame.RemoveClass('no-frame');
        glassOption.SetStyle('display', null);

        t.productForm.frame_id.value = selected.item_id;
        t.productForm.framePrice = selected.price;
        t.productForm.framePriceAlt = selected.alt_price;

        productForm.frameDescriptionOutput.Html(selected.name);
        fullFrameBtn.dataset.src = `${edoc_url_prefix}frame/${selected.frame_key}-detalj2.jpg`

        frame.updateData({
            framingType: framing_type && framing_type.value || 0,
            matW: initialFrameData.matW,
            frameW: selected.w_mm / 10,
            url: `${edoc_url_prefix}frame/${selected.frame_key}`
        });

        t.updateProductForm();

    };

    frameStore.addListener('selected_frame', handleSelectedFrame);

    frameStore.state.selected_frame && handleSelectedFrame(frameStore.state);

    t.handleGlassTrigger = function (target) {

        if (!t.activeGlass) {
            t.activeGlass = target.AddClass('selected');
        } else {

            if (target.classList.contains('locked'))
                return;

            t.activeGlass !== target && t.activeGlass.RemoveClass('selected') && (t.activeGlass = target.AddClass('selected')) ||
            target.RemoveClass('selected') && (t.activeGlass = null)
        }

        if (t.activeGlass) {
            var itemData = JSON.parse(t.activeGlass.dataset.item);

            t.productForm.glassPrice = parseFloat(itemData.price) || 0;
            t.productForm.glassPriceAlt = parseFloat(itemData.alt_price) || 0;
            t.productForm.glass_id.value = t.activeGlass.dataset.glass_id;
        } else {
            t.productForm.glassPrice = null;
            t.productForm.glassPriceAlt = null;
            t.productForm.glass_id.value = '';
        }
    };

    return t;
}