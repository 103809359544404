<template>
  <plan-picker-desktop v-model="cgroup_id" />
  <plan-picker-mobile v-model="cgroup_id" />
</template>

<script setup>
import { ref } from "vue";
import PlanPickerDesktop from "./PlanPickerDesktop.vue"
import PlanPickerMobile from "./PlanPickerMobile.vue"
import { plans } from "../data/plan";

const cgroup_id = ref(plans[0].cgroup_id);
</script>
