import React, {useState, useEffect} from 'react';

export default (store, prop) => {

  // Keep track of store value internally
  const [value, setValue] = useState(store.state[prop])

  // Updates internal state
  const stateSetter = (state) => {
    setValue(state[prop])
  };

  useEffect(() => {

    // When store prop changes, update internal state
    store.addListener(prop, stateSetter)

    return () => {
      store.removeListener(prop, stateSetter)
    }
  }, [])

  // Return updated value and setter function
  return [
    value,
    (state) => store.setState({[prop]: state}),
  ];
}