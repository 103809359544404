import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';

export default (container, options = {}) => {

  const layout = new Isotope(container, {
    transitionDuration: 0,
    itemSelector: '.shopItem',
    sortBy: 'original-order',
    masonry: {
      isFitWidth: true,
      columnWidth: '.shopItem',
      percentPosition: true,
    },
    ...options
  });

  imagesLoaded(container, () => layout.layout());

  return layout;
}