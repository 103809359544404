import request from "superagent";
import {withSelectorCallback} from "../functions";

export default ({element: formElement, data: {queryType, phoneLookupUrl}}) => {

    const lookupInput = formElement.querySelector('#register_lookup');

    const xmlTextFromNodeName = doc => tagName => {
        return doc.querySelector(tagName)?.childNodes[0].nodeValue;
    }

    const phoneLookup = query => request(phoneLookupUrl)
        .type('xml')
        .query({[queryType]: query})
        .then(res => res.xhr.responseXML)
        .then(doc => {

            const nodeValue = xmlTextFromNodeName(doc);

            // [XML tag name, DOM input name] -> {inputName: value}
            return ! doc ? [] : [
                ['name', 'name'],
                ['address', 'address1'],
                ['zipcode', 'zipcode'],
                ['city', 'city'],
                ['dd', 'dd'],
                ['mm', 'mm'],
                ['yyyy', 'yyyy'],
                ['firstname', 'firstname'],
            ]
                .reduce((accum, [tagName, inputName]) => {
                    const value = nodeValue(tagName);
                    return !value ? accum : {...accum, [inputName]: value}
                },{})
        })

    const lookup = (query) => {

        if (query.length !== 8) return;

        phoneLookup(query)
            .then(data => {
                Object.keys(data).forEach(key => {
                    const targetElement = formElement.querySelector(`[name=${key}]`);
                    targetElement && !targetElement.value && (targetElement.value = data[key])
                })
            })
    }

    window.addEventListener('click', withSelectorCallback('.customerOption', (e, target) => {
        formElement.Q('#membership').setAttribute('name', target.dataset.membership);
    }))

    lookupInput.addEventListener('keyup', e => {
        lookup(lookupInput.value.replace(/[^\d]/g, ''))
    })
}