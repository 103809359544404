import { gsap } from "gsap";
import { Power2 } from "gsap";
import shopItemsLayout from "../components/shopItemsLayout";
import {Q} from '../Jesus';
import {withSelectorCallback} from "../functions";

export default ({element:root}) => {

    const layout = shopItemsLayout(root.Q('.shop-container'));
    const iframe = Q('#embed iframe');

    const videos = root.QA('.videoLink').map((link) => {
        return {
            id: link.getAttribute('rel'),
            type: link.dataset.type
        }
    })

    const initialId = window.location.hash
        ? window.location.hash.replace('#', '')
        : videos[0].id;

    const videoSrc = {
        vimeo: id => `//player.vimeo.com/video/${id}?byline=0&amp;portrait=0&amp;badge=0`,
        youtube: id => `//www.youtube.com/embed/${id}`,
    }

    const findVideo = (_id) => videos.find(({id}) => id === _id);

    root.addEventListener('click', withSelectorCallback('.videoLink', (e, target) => {

        gsap.to(window, { duration: .7,
            scrollTo: {y: 0, autoKill: true},
            ease: Power2.easeInOut
        });

        const video = findVideo(target.getAttribute('rel'));
            iframe.src = videoSrc[video.type](video.id);
    }))

    iframe.src = videoSrc[findVideo(initialId).type](initialId);
}
