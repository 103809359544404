
export default (token) => {

    const {element:rootElement, data = {}} = token;

    function addListener(el, targetSelector) {

        el.addEventListener('click', function () {

            const targetEl = document.querySelector(targetSelector);
            targetEl && scrollToElement(targetEl, {
                duration: .8,
                ease: Power2.easeInOut,
                offset: data.offset || 20
            });
        })
    }

    addListener(rootElement, data.target);

    return token;
};