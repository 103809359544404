import {lazyModule as lazy, getModules, ui, updateModules} from "../components/moduleHandler";
import galleryWall from "./galleryWall";
import avatarGallery from "./avatarGallery"

export default ({element}) => {

    ui.galleryWall = galleryWall;
    ui.avatarGallery = avatarGallery;

    updateModules(element);
}
