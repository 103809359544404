import appEvents from "./components/appEvents";
import Events from "./events";
import {isElementIn} from "./functions";

const DropdownGroup = function(root, vars) {

    this.vars = vars || {};
    var t = this;

    this.root = root;
    root.eppo = root.eppo || {};
    if(root.eppo.dropdownGroup) {
        return root.eppo.dropdownGroup;
    }else {
        root.eppo.dropdownGroup = this;
    }

    window.addEventListener('click', this.clickHandler.bind(this));
    window.addEventListener('touchstart', testTouch);

    function testTouch() {
        t.isTouch = true;
        removeEventListener('touchstart', testTouch);
    }

    this.init();

};

DropdownGroup.prototype = {

    constructor: DropdownGroup,

    init: function() {
        this.events = appEvents[this.vars.eventsName] = new Events(this);
        this.dropdowns = [].slice.call(this.root.getElementsByClassName('eppo-dropdown'));
        this.toggles = [].slice.call(this.root.getElementsByClassName('toggle-dropdown'));
        this.closeBtns = [].slice.call(this.root.getElementsByClassName('close-dropdown'));
        this.active = false;
    },

    clickHandler: function(event) {
        var relevantTarget;
        var target = event.target;

        if((relevantTarget = isElementIn(target,this.closeBtns))) {
            //target is closeBtn
            this.toggle(false);

        }else if((relevantTarget = isElementIn(target,this.toggles))) {
            //target is toggle
            this.toggle(relevantTarget);

        }else if(!this.root.contains(event.target)) {
            //target is outside
            if(this.active) {
                this.toggle(false);
            }
        }
    },

    keyUpHandler: function(event) {
        if(event.code === 'Escape' || event.code === 'Enter') {
            event.preventDefault();
            this.toggle(false);
        }
    },

    toggle: function(target) {

        if(target) {
            target = isElementIn(target, this.dropdowns);

            if(target == this.active) {
                //if target dropdown is active
                this.deactivateItem(target);
                this.deactivate();

            }else {
                // if target is not active
                if(this.active) {
                    //deactivate last active
                    this.deactivateItem(this.active);
                }
                //activate target
                this.activateItem(target);
            }
        }else {
            //no target supplied - click is outside
            if(this.active) {
                this.deactivateItem(this.active);
                this.deactivate();
            }
        }
    },

    activateItem: function(target) {
        !this.active && this.root.classList.add('dropdownGroup_active');
        target.classList.add('active');
        addEventListener('keyup', this.keyUpHandler.bind(this));
        this.active = target;
        this.events.fireEvent('activateItem',{active:this.active});
        if(this.vars.onActivateItem) this.vars.onActivateItem.call(this,{active:this.active});
    },

    deactivateItem: function(target)  {
        target.classList.remove('active');
        removeEventListener('keyup', this.keyUpHandler);
        this.events.fireEvent('deactivateItem',{active:this.active});
        if(this.vars.onDeactivateItem) this.vars.onDeactivateItem.call(this,{active:this.active});
    },

    deactivate: function(lastTarget) {

        this.root.classList.remove('dropdownGroup_active');

        this.events.fireEvent('closed',{active:this.active});
        this.active = false;
        if(this.vars.onDeactivate) this.vars.onDeactivate({active:this.active});
    }

};

DropdownGroup.isTouch = false;
DropdownGroup.create = function(targets, vars) {

    if(targets instanceof Element) {
        targets = [targets];
    }else if(targets instanceof HTMLCollection) {
        targets = toArray(targets);
    }else if(typeof targets == 'string') {
        targets = toArray(document.querySelectorAll(targets));
    }else if(!targets instanceof Array) {
        throw 'arguments[0] "targets" is invalid ' + targets + ' - must be Element, Array, HTMLCollection or query string';
    }

    for(var i = 0; i < targets.length; i++) {
        var target = targets[i];

        if(target.eppo.dropdownGroup) { continue; }

        vars = vars || target.dataset.dropdowngroup || {};

        if(typeof vars == 'string') {
            vars = JSON.parse(vars);
        }

        new DropdownGroup(target,vars)
    }
};

export default DropdownGroup;