import {Q} from "../Jesus";
import auctionCounter from "../components/auctionCounter";
import ctaBar from "../ctaBar";
import {ui, updateModules} from "../components/moduleHandler";
import bidForm from "./bidForm";
import productGallery from "./productGallery";
import Lazy from "vanilla-lazyload";

export default ({element, data:{dateEnd}}) => {

    ui.bidForm = bidForm;
    ui.productGallery = productGallery;
    updateModules();

    new Lazy({
        elements_selector: ".__productImage",
        thresholds: '0px 0px 0px 0px',
    });

    const counterElement = Q('.countdown');
    const counter = auctionCounter(counterElement, {dateEnd})
    const toggleBids = Q('.toggle-bids');

    ctaBar(Q('.ctaBar'));

    toggleBids?.addEventListener('click', (e) => {

        e.preventDefault();
        const target = Q('.auctions');
        target.classList.toggle('active');

        toggleBids.innerText = target.classList.contains('active')
            ? 'Skjul budhistorikk'
            : 'Vis budhistorikk'
    });
}
