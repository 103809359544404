import {pushToStorage} from "../components/latest";

const pushToLatest = (token) => {

    pushToStorage(token.data);

    return token;
};

export default pushToLatest;
