import React, {Component} from 'react';
import favList from "../../modules/userLists/favList";
import {clearLatest, getLatest, removeFromLatest} from "../../components/latest";
import FavList from "./FavList";
import LatestList from "./LatestList";
import classNames from 'classnames';
import SvgIcon from "../SvgIcon";

const noop = () => {};

export default class UserLists extends Component {

    constructor(props) {

        super(props);

        favList.addListener('loading', (state) => {

            this.setState({
                loading: state.loading
            })
        });

        favList.addListener('items', (state) => {

            this.setState({
                favList: state.items,
                favCount: state.items.length,
            })
        });
    }

    state = {
        favCount: 0,
        latestCount: 0,
        favList: [],
        latestList: [],
        loading: true,
    };

    componentDidMount() {

        favList.getList();

        getLatest()
            .then((list) => {
                this.setState({
                    latestList: list,
                    latestCount: list.length,
                })
            })
    }

    handleDeleteFav = (e) => {

        const {wishlineId, itemId, context} = e.target.dataset;
        favList.trackToggle({itemId, context, action: 'delete'});
        favList.delete([wishlineId]).then(noop);
    };

    handleDeleteLatest = (e) => {

        const {productId} = e.target.dataset;
        const latestList = this.state.latestList
            .filter(item => item.product_id !== productId);

        removeFromLatest({
            type: 'product',
            id: productId,
        });

        this.setState({
            latestList,
            latestCount: latestList.length,
        });
    };

    handleTabClick = (e) => {
        const tab = e.target.value;
        this.props.onTabClick({activeTab: tab});
    };

    handleNavigate = (e, {itemId}) => {

        favList.trackNavigate({itemId})
    };

    clearLatest = () => {

        clearLatest();
        this.setState({
            latestList: [],
            latestCount: 0
        })
    };

    tabs = [
        {
            name: 'favorites',
            title: 'Favoritter',
            icon: 'heart',
        },
        {
            name: 'latest',
            title: 'Sist sette',
            icon: 'history',
        },
    ];

    render() {

        const {isLoggedIn} = glob;
        const {favList, latestList, latestCount, favCount} = this.state;
        const {onClose, activeTab} = this.props;
        const count = {
            latest: latestCount,
            favorites: favCount,
        };

        return (
            <div className='userLists'>
                <div className="userLists-header">
                    <div className="userLists-tabs">
                        {this.tabs.map(tab => (
                            <button onClick={this.handleTabClick}
                                    type='button'
                                    value={tab.name}
                                    className={classNames('userLists-tab', {
                                        'userLists-tab_active': tab.name === activeTab
                                    })}>

                                <span className="userLists-tab-inner">
                                    <SvgIcon name={tab.icon}/>
                                    <span className="userLists-tab-text">
                                        {tab.title} <span className='userLists-tab-count'>({count[tab.name]})</span>
                                    </span>
                                </span>
                            </button>
                        ))}
                    </div>

                    <button className="userLists-closeBtn flex items-center justify-center" onClick={onClose}>
                        <SvgIcon name='cross'/>
                    </button>
                </div>

                {activeTab === 'latest' &&
                <LatestList list={latestList}
                            onClear={clearLatest}
                            onDelete={this.handleDeleteLatest}/> || null}

                {activeTab === 'favorites' &&
                <FavList list={favList}
                         onDeleteFav={this.handleDeleteFav}
                         onNavigate={this.handleNavigate}
                         isLoggedIn={isLoggedIn}/> || null}
            </div>
        )
    }
}
