
import { gsap } from "gsap";
import { Power2 } from "gsap";

import './polyfills';
import './utils';
import './vendor/throwprops';

import EppoScroll from "./eppoScroll";
import TypeFilter from "./typeFilter";
import Lazy from "vanilla-lazyload";
import './vendo';
import {C, Q, QA} from "./Jesus";
import {elementWithinSelector, withSelectorCallback} from "./functions";

window.addEventListener('DOMContentLoaded', () => {

    EppoScroll.create('.eppo-scroll');

    TypeFilter.create('[data-typefilter]', {
        ignorePredicate: function (el) {
            if (!el.filterSort) return false;
            return el.filterSort === -1;
        }
    });

    window.lazyLoader = new Lazy({
        elements_selector: ".lazy",
        thresholds: '0px 0px 0px 0px',
    });
})

window.hideLoader = (loader) => {

    const tl = gsap.timeline();
    tl.to(loader, .4, {
        opacity: 0,
        ease: Power2.easeOut
    }, '+=.2')
        .add(function () {
            loader.remove();
        })
};

window.addGenericLoader = (opacity = 1, duration = .4) => {

    const loader = C('div', {'class': 'loader generic-loader'}).Append(
        C('img', {src: '/images/loader.svg'})
    );

    gsap.set(loader, {
        opacity: 0
    });

    loader.AppendTo(Q('.main-content'));

    gsap.to(loader, { duration: duration,
        opacity: opacity
    });

    return loader;
};

window.addEventListener('click', withSelectorCallback(
    '.dropdown:not(.eppo-dropdown):not(.dropdown_disabled) .toggle', (e, target) => {
        target.closest('.dropdown').classList.toggle('active');
    }, (e) => {
        const active = Q('.dropdown:not(.eppo-dropdown).active');
        const inside = active && elementWithinSelector(e.target, '.dropdown.active');
        active && !inside && active.classList.remove('active');
    })
)
