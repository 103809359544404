// Styles
import './styles'

import './script/app.js';

// Vue
import { createApp } from 'vue'

// import components
import Benefits from './components/Benefits.vue'
import ButtonShowBenefitsModal from './components/ButtonShowBenefitsModal.vue'
import Modal from './components/Modal.vue'
import RegisterPlanPicker from './components/RegisterPlanPicker.vue'
import Upgrade from './components/Upgrade.vue'

// Reference imported components
const components = {
  Benefits,
  ButtonShowBenefitsModal,
  Modal,
  RegisterPlanPicker,
  Upgrade,
}

// Instantiate the Vue apps
for (const el of document.getElementsByClassName('vue-component')) {
  createApp({
    template: el.innerHTML,
    components
  }).mount(el)
}
