import SimpleSlider from "../components/simpleSlider";

import Lazy from 'vanilla-lazyload';
import Frame from "../workshopFrame";

export default (token) => {

    const {element:rootElement} = token;

    const loader = rootElement.parentElement.Q('.loader');
    loader && window.hideLoader(loader);

    const frameCandidates = rootElement.QC('galleryWall-frame');
    frameCandidates.forEach(f => new Frame(f));

    rootElement.RemoveClass('galleyWall_loading');

    const sliderCandidate = rootElement.Q('.galleryWall-items');
    const sliderNavCandidate = rootElement.Q('.galleryWall-thumbsList');
    const sliderThumbItems = sliderNavCandidate.QC('galleryWall-thumbWrap'); // Thumbs
    const sliderNavItems = rootElement.QC('galleryWall-navItem'); // Arrows

    // performance.mark('gw:start');

    var slidingFrames = new SimpleSlider(sliderCandidate, {
        constrainDrag: true,
        maxDuration: 1
    });

    var slidingThumbs = new SimpleSlider(sliderNavCandidate, {
        constrainDrag: false
    });

    sliderThumbItems.forEach((item, i) => {

        item.slider = {
            nav: 'index',
            i,
        };
        item.addEventListener('click', navItemClickHandler)
    });

    sliderNavItems.forEach((item, i) => {

        item.slider = {
            nav: i && 'next' || 'prev',
        };
        item.addEventListener('click', navItemClickHandler)
    });

    function navItemClickHandler() {

        let goToIndex = 0;

        switch (this.slider && this.slider.nav) {
            case 'index':
                goToIndex = this.slider.i;
                break;

            case 'next':
                goToIndex = slidingFrames.activeIndex +1;
                break;

            case 'prev':
                goToIndex = slidingFrames.activeIndex -1;
                break;

        }

        slidingFrames.goTo(goToIndex);
    }

    function setActiveThumb(index) {

        const activeThumbClassName = 'galleryWall-thumbWrap_active';
        const activeThumb = sliderNavCandidate.Q(`.${activeThumbClassName}`);
        activeThumb && activeThumb.classList.remove(activeThumbClassName);
        slidingThumbs.cells[index].classList.add(activeThumbClassName);
    }

    function setNavItemsStatus() {

        const disabledClassName = 'galleryWall-navItem_disabled';
        sliderNavItems.forEach(item => item.classList.remove(disabledClassName));
        slidingFrames.isFirst && sliderNavItems[0].classList.add(disabledClassName);
        slidingFrames.isLast && sliderNavItems[1].classList.add(disabledClassName);
    }

    slidingFrames.on('activate', e => {
        slidingThumbs.goTo(e.activeIndex);
        setActiveThumb(e.activeIndex);
        setNavItemsStatus();
    });

    setActiveThumb(slidingFrames.activeIndex);
    setNavItemsStatus();

    new Lazy({
        elements_selector: ".sliderImage_idle",
        callback_loaded: el => {
            el.RemoveClass('sliderImage_idle');
        }
    });

    new Lazy({
        elements_selector: ".galleryWall-thumb",
        callback_loaded: el => {
            slidingThumbs.setBounds()
        }
    });

    return token;
};