export default [
    {
        src: '/images/surroundings/surroundings-thumbnail-1.jpg',
        id: 1,
        type: 'big',
        room: {
            h: 220,
            w: 530
        },
        bounds: {
            bottom: 84
        },
        elements: [
            {
                src: '/images/surroundings/sofa.png',
                w: 280,
                h: 93.5,
                x: 0,
                y: {fromBottom: 0},
                out: 'down',
                in: 'up'
            },
            {
                src: '/images/surroundings/vase.png',
                w: 90,
                h: 120,
                x: -140,
                y: {fromBottom: 0},
                out: 'down',
                in: 'right'
            },
            {
                src: '/images/surroundings/lamp.png',
                w: 35,
                h: 80,
                x: 120,
                y: 0,
                out: 'up',
                in: 'down'
            }
        ]
    },
    {
        src: '/images/surroundings/surroundings-thumbnail-2.jpg',
        id: 2,
        type: 'big',
        bounds: {
            bottom: 80
        },
        room: {
            h: 220,
            w: 530
        },
        elements: [
            {
                src: '/images/surroundings/bed.png',
                w: 220,
                h: 87.3,
                x: 0,
                y: {fromBottom: 0},
                out: 'down',
                in: 'up'
            },
            {
                src: '/images/surroundings/dressing_table.png',
                w: 180,
                h: 128.5,
                x: -150,
                y: {fromBottom: 0},
                out: 'left',
                in: 'up'
            },
            {
                src: '/images/surroundings/closet.png',
                w: 171.6,
                h: 180,
                x: 150,
                y: {fromBottom: 0},
                out: 'right',
                in: 'left'
            }
        ]
    },
    {
        src: '/images/surroundings/surroundings-thumbnail-3.jpg',
        id: 3,
        type: 'big',
        bounds: {
            bottom: 40
        },
        room: {
            h: 200,
            w: 500
        },
        elements: [
            {
                src: '/images/surroundings/chair-small.png',
                w: 130.6,
                h: 90,
                x: -100,
                y: {fromBottom: 0},
                out: 'left',
                in: 'right'
            },
            {
                src: '/images/surroundings/shelves.png',
                w: 180,
                h: 90,
                x: 160,
                y: 0,
                out: 'right',
                in: 'left'
            }
        ]
    },
    {
        src: '/images/surroundings/surroundings-thumbnail-4.jpg',
        id: 4,
        type: 'big',
        bounds: {
            bottom: 70
        },
        room: {
            h: 280,
            w: 550
        },
        elements: [
            {
                src: '/images/surroundings/couches_combo.png',
                w: 370,
                h: 70.7,
                y: {fromBottom: 0},
                x: 0,
                out: 'down',
                in: 'up'
            }
        ]
    },
    {
        src: '/images/surroundings/surroundings-thumbnail-5.jpg',
        id: 5,
        type: 'big',
        bounds: {
            bottom: 69
        },
        room: {
            h: 280,
            w: 550
        },
        elements: [
            {
                src: '/images/surroundings/diningtable02.png',
                w: 300,
                h: 57,
                y: {fromBottom: 0},
                x: 0,
                out: 'down',
                in: 'up'
            }
        ]
    }
];