import { gsap } from "gsap";
import SimpleSlider from "../components/simpleSlider";
import {Q} from '../Jesus';

const avatarGallery = (token) => {

    var {element: rootElement} = token;

    var dragItem = rootElement.Q(`.avatarItems-itemsList`);
    var swipeTease = Q('.avatarItems-swipeTease');
    var slidingAvatars = new SimpleSlider(dragItem);

    slidingAvatars.once('interaction', removeSwipeTease);

    function removeSwipeTease() {
        gsap.to(swipeTease, { duration: .4,
            opacity: 0,
            onComplete: () => swipeTease.remove,
        });
    }

    return token;
};

export default avatarGallery;
