import cartHandler from "../components/cartHandler";
import {withSelectorCallback} from "../functions";
import {C, Q} from "../Jesus";
import {modal} from "./modal";
import React, {useEffect} from "react";
import workshop from "../workshop";
import SvgIcon from "../reactComponents/SvgIcon";
import request from "superagent";

export default ({element}) => {
    const cart = cartHandler.getInstance();

    const handleHtml = html => {

        const tmp = C('div').Html(html);
        const workshopEl = tmp.Q('.main-content');

        modal.setComponent({
            Component: ({onClose}) => {

                useEffect(() => {
                    const shop = workshop({root: Q('.workshop')})
                        .init();

                    return () => {
                        shop.destroy();
                    }
                }, [])

                return (
                    <div className={'frameAdder-inner'}>
                        <div className={'frameAdder-head'}>
                            <h2 className={'frameAdder-h'}>Legg til ramme</h2>
                            <button className="frameAdder-closeBtn" onClick={onClose}>
                                <SvgIcon name='cross'/>
                            </button>
                        </div>
                        <div className={'frameAdder-content'} dangerouslySetInnerHTML={{__html: workshopEl.outerHTML}}/>
                    </div>
                )
            },
            modalProps: {
                className: 'frameAdder'
            }
        })
            .open();
    }


    window.addEventListener('click', withSelectorCallback('.__addFrame', (e, target) => {
        e.preventDefault();

        const url = target.href;

        request.get(url)
            .then(res => res.text)
            .then(handleHtml)
    }))
}