import request from 'superagent';

let req;

export default async (url, active) => {

    req && req.abort && req.abort();

    req = await request(url)
        .query({'_': Date.now(), active})
        .catch((err) => {
            console.log(err, 'HOY');
            return {}
        })
        .then(res => res.body);

    return req;
}