import { gsap } from "gsap";
import { Power2 } from "gsap";
import { Back } from "gsap/all";

gsap.registerPlugin(Back);

const lock = (()=> {

    let locked = 0,
        tempScrollTop;

    function getViewportScrollbarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    return  {
        add: function() {
            if(locked === 0) {
                tempScrollTop = (window.scrollY || window.pageYOffset);
                gsap.set(document.documentElement,{top:-tempScrollTop, paddingRight:getViewportScrollbarWidth()});
                document.documentElement.classList.add('locked-view');
            }
            locked ++
        },
        remove: function() {
            if(locked === 1) {
                document.documentElement.classList.remove('locked-view');
                gsap.set(document.documentElement,{top:0, paddingRight: ''});
                window.scrollTo(false, tempScrollTop);
            }
            locked --
        }
    };
})();

export const modalIn = function () {

    const {opacity} = this.props;
    const {shouldLock} = this.props;
    const tl = gsap.timeline();

    shouldLock && lock.add();

    tl
        .set([
            this.$overlay.current,
            this.$inner.current
        ], {opacity: 0})
        .set(this.$inner.current, {
            scale: .94
        })
        .to(this.$overlay.current, .17, {
            opacity: opacity,
            ease: Power2.easeOut,
        })
        .to(this.$inner.current, .4, {
            opacity: 1,
            scale: 1,
            ease: Back.easeOut,
            force3D: true,
        }, .2);
}

export const modalOut = function () {

    const {shouldLock} = this.props;
    const tl = gsap.timeline();

    tl
        .to(this.$inner.current, .23, {
            opacity: 0,
            scale: 1.035,
            ease: Power2.easeOut,
            force3D: true,
        })
        .to(this.$overlay.current, .3, {
            opacity: 0,
            ease: Power2.easeOut,
        }, .2)
        .add(shouldLock && lock.remove || noop);
}

export const panelIn = function () {

    const {opacity} = this.props;
    const {shouldLock} = this.props;
    const tl = gsap.timeline();

    shouldLock && lock.add();

    tl
        .set([
            this.$overlay.current,
        ], {opacity: 0})
        .set(this.$inner.current, {
            xPercent: 100,
        })
        .to(this.$overlay.current, .17, {
            opacity: opacity,
            ease: Power2.easeOut,
        })
        .to(this.$inner.current, .4, {
            xPercent: 0,
            ease: Power2.easeOut,
            force3D: true,
        }, .2);
}

export const panelOut = function () {

    const tl = gsap.timeline();
    const {shouldLock} = this.props;

    tl
        .to(this.$inner.current, .23, {
            xPercent: 100,
            ease: Power2.easeInOut,
            force3D: true,
        })
        .to(this.$overlay.current, .3, {
            opacity: 0,
            ease: Power2.easeOut,
        }, .2)
        .add(shouldLock && lock.remove || noop);
}
