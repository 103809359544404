export const elementWithinSelector = (el, s) => {

  while (!el.matches(s)) {
    el = el.parentElement;
    if (el == null) return false;
  }
  return el;
};

export const elementWithinClass = (el, c) => {

  while (el && !el.classList.contains(c)) {
    el = el.parentElement;
  }
  return el;
};

export const elementWithinTag = (el, tagName) => {

  tagName = tagName.toUpperCase();
  while (el && el.tagName !== tagName) {
    el = el.parentElement;
  }
  return el;
}

export const isElement = (el, targetEl, d = Infinity) => {

  if (el === targetEl)
    return el;

  for (var i = 0; i < d; i++) {

    if (!el)
      return false;
    if (el === targetEl)
      return el;

    el = el.parentElement;
  }
}

export const isElementIn = (el, collection, d = Infinity) => {

  var cLen = collection.length;

  //loop collection for first level
  for (var i = 0; i < cLen; i++) {
    if (el === collection[i])
      return el;
  }

  // for each level in depth
  for (var j = 0; j < d; j++) {
    el = el.parentElement;
    if (!el) {
      return false;
    }

    //loop collection for nth level
    for (var k = 0; k < cLen; k++) {
      if (el === collection[k]) {
        return el;
      }
    }
  }
}

/**
 *
 * @param selector {string}
 * @param  hitFn {function(UIEvent, HTMLElement):void}
 * @param missFn {function(UIEvent):void}
 * @returns {function(*=): void}
 */
export const withSelectorCallback = (selector, hitFn, missFn = null) => (e) => {
  const target = elementWithinSelector(e.target, selector)
  target && hitFn && hitFn(e, target);
  !target && missFn && missFn(e)
}

/**
 *
 * @param s {string} query string
 * @returns {{}} parsed query
 */
export const parseQuery = (s) => {

  let trimIndex;
  if ((trimIndex = s.indexOf('?')) > -1) {
    s = s.substring(trimIndex, s.length)
  }

  return s.replace('?&', '').replace('?=', '').replace('?', '').split('&').reduce(function (a, b, i) {

    b = b.split('=').reduce(function (a, b, i) {

      !i && (a[b] = '' && true) // todo: logical err
      || (a[Object.keys(a)[0]] = b.split(','));

      return a;
    }, {});

    var key = Object.keys(b)[0];
    a[key] = b[key];

    return a;
  }, {});
};

export const toQueryString = function (o, omit) {

  typeof omit == 'string' && (omit = [omit]) || omit instanceof Array || (omit = []);

  return Object.keys(o).filter(function (key) {
    return omit.indexOf(key) === -1
  }).map(function (key) {
    return key + '=' + o[key].join(',')
  }).join('&');
}

export const thousandSeparate = (n, delimiter) => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
}

export const roundHalf = (num) => Math.round(num * 2) / 2;

export const throttle = (fn, time, thisArg) => {

  thisArg = thisArg || window;

  var timer;

  return function () {
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(thisArg, arguments)
    }, time);
  }
}

export const closestNumIn = (num, arr) => {
  var curr = arr[0];
  var diff = Math.abs(num - curr);
  for (var val = 0; val < arr.length; val++) {
    var newdiff = Math.abs(num - arr[val]);
    if (newdiff < diff) {
      diff = newdiff;
      curr = arr[val];
    }
  }
  return curr;
}

export const getScrollbarWidth = () => {
  var el = document.createElement('div');
  el.style.position = 'fixed';
  el.style.overflow = 'scroll';
  el.style.width = '100px';
  el.style.height = '100px';

  //just to be x-browser safe
  var child = document.createElement('div');
  child.style.width = '120px';
  child.style.height = '120px';
  el.appendChild(child);

  document.body.appendChild(el);
  var sw = el.getBoundingClientRect().width - el.clientWidth;
  el.remove();
  return sw;
}

export const sortByProp = (prop, ascending = true) => (a, b) => {

  const aVal = a[prop];
  const bVal = b[prop];

  const ret = aVal === bVal ? 0 :
              aVal < bVal ? -1 :
              1;

  return ascending ? ret : ret * -1;
}
