import {withSelectorCallback} from "../functions";
import {Q} from '../Jesus';

export default ({element:root}) => {

    const inputs = root.QA('[name="auth_code_show"]');
    const form = root.closest('form');

    form.addEventListener('change', withSelectorCallback('[name="auth_code_show"]', (e, target) => {
        toggle(target.value)
    }))

    const toggle = (show) => {

        if(show === '0')
            Q('.__inputContainer').style.setProperty('display', 'none');

        if(show === '1')
            Q('.__inputContainer').style.setProperty('display', 'block');
    }

    toggle(Q('[name="auth_code_show:checked"]')?.value);
}