import {Q} from './Jesus';
import resizeListener from "./components/resizeListener";

const Frame = function (root, vars = {}) {

    const nada = document.createElement('div');
    this.frame = root || Q('.cFrame');
    this.mat = this.frame.Q('.cFrame-mat');
    this.paper = this.frame.Q('.cFrame-paper') || nada;
    this.gap = this.frame.Q('.cFrame-gap') || nada;
    this.img = this.frame.Q('.cFrame-subject');
    this.imageContainer = this.frame.Q('.cFrame-subjectContainer');
    this.rulers = vars.rulers;

    this.init();
};

Frame.prototype = {

    constructor: Frame,

    init: function () {

        this.frame.Frame = this;

        this.updateData(this.initialData());
        this.setMatClass();
        this.updateSize();
        this.resizeHandler = this.updateSize.bind(this);
        this.rs = resizeListener.add({
            el: this.img,
            cb: this.resizeHandler
        });
    },

    setMatClass: function () {

        const {artH, matW} = this.data;

        const modName =
            artH < matW * 2.0 ? 'xxl' :
            artH < matW * 2.4 ? 'xl' :
            artH < matW * 3.8 ? 'l' :
            artH < matW * 6.0 ? 'm' :
            null;

        modName && this.mat.classList.add(`cFrame-mat_${modName}`);
    },

    destroy: function () {

        ResizeListener.remove(this.rs);
        this.frame = null;
        this.mat = null;
        this.data = null;
        this.img = null;
    },

    bgVariations: ['-ne', '-se', '-sw', '-nw', '-n', '-e', '-s', '-w', '-w'],

    updateBg: function (bgUrl) {

        const url = bgUrl || this.data.url;

        !url && this.frame.style.removeProperty('backgroundImage')
        || (this.frame.style.backgroundImage = this.bgVariations
                .map(s => `url(${url + s}.jpg)`)
                .join(','))

        this.updateSize();
    },

    getFactor() {
        return this.img.clientWidth / this.data.artW
    },

    getGapPaddingStyle() {

        const {noFrame, frameGap} = this.data;

        return noFrame
            ? '0'
            : `${frameGap * this.getFactor()}px`;
    },

    getSubjectPaddingStyle() {

        const {subjectGap} = this.data;

        return `${(subjectGap || 0) * this.getFactor()}px`;
    },

    getPaperPaddingStyle() {

        const {
            artW,
            artH,
            mediumW,
            mediumH,
            dim,
            noFrame,
        } = this.data;

        // no paper size for dim="medium", maybe should be for noFrame?
        return dim !== 'medium' || noFrame
            ? '0'
            : [(mediumH - artH) / 2, (mediumW - artW) / 2]
                .map(n => n * this.getFactor() + 'px')
                .join(' ');

    },

    getMatPaddingStyle() {

        const {
            matW,
            noFrame,
            framingType
        } = this.data;

        const factor = this.getFactor();

        switch (framingType) {

            case 0: // standard with passepartout
            case 3: // stuck with passepartout
                const matWith = noFrame ? 0 : matW;

                return [matWith, matWith, matWith + 1, matWith]
                    .map((p) => p * factor + 'px').join(' ');

            default:
                return '0';
        }
    },

    updateSize: function () {

        const {data} = this;

        const frameWidth = data.noFrame ? 0 : data.frameW;
        const factor = this.img.clientWidth / data.artW;

        this.mat.style.padding = this.getMatPaddingStyle();
        this.paper.style.padding = this.getPaperPaddingStyle();
        this.gap.style.padding = this.getGapPaddingStyle();
        this.img.style.padding = this.getSubjectPaddingStyle();

        const bgSize = frameWidth * factor + 'px';
        this.frame.style.padding = bgSize;
        this.frame.style.backgroundSize = [...new Array(8).fill(bgSize), 'cover'].join(',');
    },

    initialData() {
        const {dataset: {
            framingType,
            matWidth: matW = this.mat.dataset.matWidth,
            frameWidth: frameW,
            artWidth: artW,
            artHeight: artH,
            mediumWidth: mediumW,
            mediumHeight: mediumH,
            frameGap = 0,
            subjectGap = 0,
            url = null,
            noFrame = this.frame.classList.contains('no-frame')
        }} = this.frame;

        return {
            dim: 'subject',
            framingType: framingType ? Number(framingType) : 0,
            frameGap: frameGap ? Number(frameGap) : 0,
            matW: matW ? Number(matW) : 0,
            frameW: frameW ? Number(frameW) : 0,
            artW: Number(artW),
            artH: Number(artH),
            mediumW: Number(mediumW),
            mediumH: Number(mediumH),
            subjectGap,
            url,
            noFrame,
        }
    },

    updateData: function (newData = {}) {

        const noFrame = this.frame.classList.contains('no-frame');

        this.data = {
            ...this.data,
            ...newData,
            noFrame,
        };

        this.rulers && this.rulers.updateRulers(this);

        const typeClassNames = [
            'standard',
            'floating',
            'boxed',
            'stuckPass',
            'stuck',
        ];

        const typeClass = typeClassNames[this.data.framingType];

        typeClassNames.forEach(cn => this.imageContainer.classList.remove(cn));
        this.imageContainer.classList.add(typeClass);

        typeClassNames.forEach(cn => this.frame.classList.remove(`cFrame_${cn}`));
        this.frame.classList.add(`cFrame_${typeClass}`);

        this.updateBg();
    }
};

export default Frame;
