import {modal} from "./modal";
import BidAdded from "../reactComponents/BidAdded";
import React from "react";
import {errToast} from "./toaster";
import request from 'superagent';

export default (token) => {

    const {element:form} = token;
    let waiting = false;

    const submitHandler = async (e) => {

        e.preventDefault();

        if(waiting) return;
        waiting = true;

        const payload = new FormData(form);
        payload.append('place_bid_x', '1')

        const res = await request.post(form.action)
            .accept('application/json')
            .send(payload)
            .then(res => res.body)
            .catch(errToast)

        waiting = false;

        res && res.bid && modal.setComponent({
            Component: BidAdded,
            props: {
                ...res,
                onClose: () => location.reload(),
            }
        })
            .open();
    }

    form.addEventListener('submit', submitHandler)

    return token;
}

