import React from "react";
import {E} from "./HelperComponents";

const formatPrice = (price) => {
  return `${price.replace(',00', '')},-`
};

const noop = () => {
};

const Aitem = ({aitem, bid}) => {

  const {name, img, href} = aitem;
  const {bid_initial} = bid;

  return (
    <div className="uAdded-item">
      <div className="uAdded-item-image">
        <img src={img}/>
      </div>
      <div className="uAdded-item-details">
        <h3 className="uAdded-item-h">
          <E>{`Ditt bud på "${name}" er registrert`}</E>
        </h3>
        <div className="uAdded-item-bid">Bud kr: {formatPrice(bid_initial)}</div>
      </div>
    </div>
  )
}

const BidAdded = (props) => (
  <div className='uAdded uAdded_cart'>
    <div className='uAdded-header'>
      <h1 className='uAdded-h'>Budbekreftelse</h1>
    </div>
    <div className="uAdded-items">
      <Aitem {...props}/>
    </div>
    <div className="uAdded-buttons">
      <a onClick={() => location.reload()} href="#" className="btn btn_primary btn_lg">Oppdater</a>
    </div>
  </div>
)

export default (props) => {

  const {aitem, messages, bid, onClose = noop} = props;

  return (
    (aitem && bid)
    && <BidAdded {...props}/>
    || <div>Noe gikk galt, gitt! Error meldinger her</div>
  )
}
