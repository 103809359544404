
import 'superagent';
import './bootstrap';
import {lazyModule, ui} from './components/moduleHandler';
// import "../style/app.scss";
import modal from './modules/modal';
import nav from './modules/nav';
import consentBox from './modules/consentBox'
import home from './modules/home';
import auction from './modules/auction';
import filter from './modules/filter';
import product from './modules/product';
import cart from './modules/cart';
import registerForm from './modules/registerForm';
import itemsLayout from './modules/itemsLayout';
import giftCard from './modules/giftCard';
import relatedSticky from './modules/relatedSticky';
import docSlider from './modules/docSlider';
import slidingItems from './modules/slidingItems';
import imageBox from './modules/imageBox';
import infiniteScroll from './modules/infiniteScroll';
import artists from './modules/artists';
import articles from './modules/articles';
import tv from './modules/tv';

window.glob = {
    isLoggedIn: false
};

ui.glob = ({data}) => window.glob = {...window.glob, ...data};

ui.modal = modal
ui.nav = nav
ui.consentBox = consentBox
ui.home = home
ui.auction = auction
ui.filter = filter
ui.product = product
ui.cart = cart
ui.registerForm = registerForm
ui.itemsLayout = itemsLayout
ui.giftCard = giftCard
ui.relatedSticky = relatedSticky
ui.docSlider = docSlider
ui.slidingItems = slidingItems
ui.imageBox = imageBox
ui.infiniteScroll = infiniteScroll
ui.artists = artists
ui.articles = articles
ui.tv = tv


// asyncApi.then(({setHtml, setComponent, open}) => {
//
//     console.log(open);
//     setComponent({
//         Component: Login
//     })
//
//     open();
// })
