import SimpleSlider from "../components/simpleSlider";

export default (token) => {

    const {element:rootElement} = token;

    const sliderCandidate = rootElement.Q('.listItems');
    const sliderArrows = rootElement.QC('slidingItems-arrow');
    const disabledArrowClass = 'slidingItems-arrow_disabled';

    var slidingItems = new SimpleSlider(sliderCandidate, {
        centered: false,
        snap: true,
    });

    slidingItems.on('atEdge',  setStatus);

    function setStatus() {

        slidingItems.atStart
            && sliderArrows[0].AddClass(disabledArrowClass)
            || sliderArrows[0].RemoveClass(disabledArrowClass);

        slidingItems.atEnd
            && sliderArrows[1].AddClass(disabledArrowClass)
            || sliderArrows[1].RemoveClass(disabledArrowClass);
    }

    const step = window.innerWidth > 920 ? 2 : 1;

    const next = ()=> slidingItems.goTo(slidingItems.activeIndex + step);
    const prev = ()=> slidingItems.goTo(slidingItems.activeIndex - step);

    [prev, next].forEach((fn, i) => sliderArrows[i].addEventListener('click', fn));

    setStatus();

    token.destroy = function () {
        [prev, next].forEach((fn, i) => sliderArrows[i].removeEventListener('click', fn));
        slidingItems.destroy();
    };

    return token;
};
