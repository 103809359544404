import React from "react";

export default (
    {
        selected,
        name,
        entries = [],
        onChange = console.log,
        className = ''
    }) => {

    const handleChange = (e) => {
        onChange(e);
    };

    return (
        <select name={name} onChange={handleChange} className={className}>
            {entries.map((o) =>
                <option key={o.value}
                        value={o.value}
                        disabled={o.disabled || false}
                        selected={o.value === selected}>
                    {o.name}
                </option>)}
        </select>
    )
}
