<template>
  <modal ref="modal">
    <BenefitsDesktop :current-plan="currentPlan" class="hidden sm:block"></BenefitsDesktop>
    <BenefitsMobile :current-plan="currentPlan" class="sm:hidden"></BenefitsMobile>
  </modal>

  <button @click="modal.openModal()" class="flex items-center text-right">Se alle nivåer og fordeler her
    <svg class="ml-2 stroke-none w-6" xmlns="http://www.w3.org/2000/svg" width="30" height="30"
         fill="none" viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" fill="#000"/>
      <path fill="#fff" d="M8 14h14v2H8z"/>
      <path fill="#fff" d="M14 8h2v14h-2z"/>
    </svg>
  </button>
</template>

<script setup>
import { ref } from 'vue'
import Modal from './Modal.vue'
import BenefitsDesktop from './BenefitsDesktop.vue'
import BenefitsMobile from './BenefitsMobile.vue'

defineProps(['currentPlan'])

const modal = ref(null)
</script>
