// eppoScroll /////////////////////////////

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

import {C} from './Jesus';
import Events from './events';
import {getScrollbarWidth, throttle} from "./functions";

import ResizeListener from "./components/resizeListener";

gsap.registerPlugin(Draggable);

var scrollTester = document.createElement('div');
scrollTester.classList.add('scroll-measure');

var EppoScroll = function(target, config = {}) {

    if(!target) {
        console.error('First argument "target" not provided. Aborting...');
        return;
    }

    if (!target){
        console.error('first argument "target" not provided, aborting');
        return;
    }
    this.vars = {
        acceptedScrollBarWidth : 5,
        ...config
    };

    this.events = new Events;
    const  t = this;

    this.fireHitBottomEvent = throttle(function () {
        t.events.fireEvent('hitBottom');
    }, 50);

    this.scrollBarWidth = this.getScrollbarWidth();

    this.outer = C('div').AddClass('scroll-outer');
    this.host = C('div').AddClass('scroll-host');
    this.inner = C('div').AddClass('scroll-inner');
    this.target = target.AddClass('scroll-target');

    this.init();

    target.eppoScroll = this;
    this.onScroll();
};

EppoScroll.prototype = {

    constructor: EppoScroll,

    init: function() {

        if(this.scrollBarWidth <= this.vars.acceptedScrollBarWidth) {
            this.disabled = true;
            this.onScroll = function () { }
        }

        var outer = this.outer,
            host = this.host,
            target = this.target,
            inner = this.inner,
            t = this;

        outer.style.cssText = 'overflow:visible; position:relative';
        host.style.position = 'relative';
        host.style.overflow = 'hidden';

        inner.style.cssText = 'position: relative; top:0; overflow: hidden; overflow-y: scroll; width: calc(100% + ' + (this.scrollBarWidth) + 'px);';

        //add to DOM

        var fragment = document.createDocumentFragment();
        fragment.appendChild(outer);
        outer.appendChild(host);
        host.appendChild(inner);
        target.parentElement.insertBefore(fragment, target);
        inner.appendChild(target);


        if(this.vars.onInit) {
            this.vars.onInit({target: target, host: host, inner: inner})
        }

        this.createScrollTrack();
        this.boundOnScroll = this.onScroll.bind(this);

        inner.addEventListener('scroll', this.boundOnScroll);

        var nullObj = document.createElement('div');
        var startY,
            yMovement;

        var a = performance.now();

        this.draggable = Draggable.create(nullObj, {
            type: 'y',
            trigger: this.scrollHandle,
            cursor: 'initial',
            onDragStart: function() {
                t.starDragScrollY = t.inner.scrollTop;
                t.starDragHandleY = t.handleY;
            },
            onDrag: function() {
                t.onDragHandle(this.y);
            },
            onDragEnd: function() {
                gsap.set(nullObj, {y:0})
            }
        })[0];

        var b = performance.now();

        this.boundOnClickTrack = this.onClickTrack.bind(this);
        this.scrollTrack.addEventListener('click', this.boundOnClickTrack);


        this.resizeListener = ResizeListener.add({
            el: target,
            cb: this.onScroll.bind(this)
        });

        this.constructor.instances.push(this);
    },

    destroy: function () {

        var t = this;
        this.draggable.kill();
        this.draggable = null;
        this.inner.removeEventListener('scroll', this.boundOnScroll);
        this.scrollTrack.removeEventListener('click', this.boundOnClickTrack);
        ResizeListener.remove(this.resizeListener);

        var instancePosition = this.constructor.instances.find(function (instance, i) {
            return instance.target == t.target && i +1;
        }) - 1;
        this.constructor.instances.splice(instancePosition, 1);
    },

    createScrollTrack: function() {
        var track = this.scrollTrack = document.createElement('div').AddClass('scroll-track');
        var handle = this.scrollHandle = document.createElement('div').AddClass('scroll-handle');
        handle.display = 'block';
        var parent = this.host.parentElement;

        track.appendChild(handle);
        parent.appendChild(track);
    },

    updateHandleHeight: function() {
        this.handleHeight = this.scrollTrack.clientHeight * (this.host.clientHeight/this.target.clientHeight);
        this.scrollHandle.style.height = this.handleHeight+'px';
    },

    onClickTrack: function(event){
        if(event.target == this.scrollHandle) return;
        var progress = event.offsetY/this.trackHeight;
        this.inner.scrollTop = Math.round(this.overflowHeight * progress);
    },

    onScroll: function() {

        var targetHeight = this.target.clientHeight;
        var hostHeight = this.host.clientHeight;
        var trackHeight = this.trackHeight = this.scrollTrack.clientHeight;
        var scrollY = this.inner.scrollTop;
        var overflowHeight = this.overflowHeight = targetHeight - hostHeight;
        var scrollProgress = this.scrollProgress = (100/overflowHeight * scrollY)/100;

        this.updateHandleHeight();
        var trackHeightLeft = this.trackHeightLeft = trackHeight - this.handleHeight;
        var handleY = this.handleY = Math.round(scrollProgress * trackHeightLeft);

        //this.scrollHandle.style.transform = 'translateY('+ handleY + 'px)';
        gsap.set(this.scrollHandle, {
            y: handleY,
            force3D:true
        });

        scrollProgress > 0.98 && this.fireHitBottomEvent();

        if(overflowHeight < 0) {
            this.scrollTrack.style.display = 'none';
            this.fireHitBottomEvent()
        }else {
            this.scrollTrack.style.removeProperty('display');
        }
    },

    onDragHandle: function(movement) {
        var progress = ((this.starDragHandleY + movement) / this.trackHeightLeft);
        progress = Math.min(Math.max(progress, 0), 1);
        this.inner.scrollTop = Math.round(this.overflowHeight * progress);
    },

    getScrollbarWidth: function() {
      return getScrollbarWidth();
    }
};

EppoScroll.instances = [];

EppoScroll.create = function(targets, vars) {

    if(targets instanceof Element) {
        targets = [targets];
    }else if(targets instanceof HTMLCollection) {
        targets = toArray(targets);
    }else if(typeof targets == 'string') {
        targets = toArray(document.querySelectorAll(targets));
    }else if(!targets instanceof Array) {
        throw 'arguments[0] "targets" is invalid ' + targets + ' - must be Element, Array, HTMLCollection or query string';
    }

    for(var i = 0; i < targets.length; i++) {
        var target = targets[i];

        if(target.eppoScroll) { continue; }

        vars = vars || target.dataset.epposcroll || {};

        if(typeof vars == 'string') {
            vars = JSON.parse(vars);
        }

        new EppoScroll(target,vars)
    }
};

export default EppoScroll;
