import { gsap } from "gsap";
import { Power2 } from "gsap";
import { Draggable } from "gsap/Draggable";
// import debounce from 'lodash.debounce';
import EventEmitter from 'EventEmitter';
import ResizeListener from '../components/resizeListener'
import {closestNumIn} from "../functions";
import '../vendor/throwprops';

gsap.registerPlugin(Draggable);

const noop = function () {};

const EVENTS = Object.freeze({
    interaction: 'interaction',
    move: 'move',
    goTo: 'goTo',
    activate: 'activate',
    dragStart: 'dragStart',
    dragEnd: 'dragEnd',
    throwEnd: 'throwEnd',
    updateBounds: 'updateBounds',
    atEdge: 'atEdge',
});

export default class SimpleSlider extends EventEmitter {

    constructor(dragElement, vars = {}, draggableVars = {}) {

        super();

        const self = this;
        this.isTouched = false;
        this.dragElement = dragElement;
        this.dragElement.classList.add('simpleSlider');
        this.activeIndex = null;

        this.vars = {
            initialIndex: 0,
            maxDuration: 1.5,
            minDuration: .5,
            durationFactor: 2000,
            centered: true,
            activeCellClass: 'simpleSlider_active',
            constrainDrag: false,
            onFirstDrag: noop,
            onFirstInteraction: noop,
            snap: true,
            onChange: noop,
            boundsElement: dragElement.parentElement,
            cells: toArray(dragElement.children),
            ...vars
        };

        this.cells = this.vars.cells;

        if(!this.vars.cells.length) {
            this.invalid = true;
            return;
        }

        this.vars.constrainDrag && (this.vars.snap = false);
        this.vars.snap && this.setSnapValues();

        // Clean up snap
        let snap;
        switch (this.vars.snap) {

            case true:
                snap = {
                    x: function(endValue) {
                        // const minX = self.getBounds().minX;
                        const snapVal = closestNumIn(endValue, [...self.snapValues]);
                        self.snapIndex = self.snapValues.indexOf(snapVal);
                        // console.log();
                        return snapVal;
                    }
                };
                break;

            default:
                snap = false;
        }

        this.draggableOptions = {
            type: 'x',
            cursor: 'grab',
            snap,
            trigger: this.cells,
            force3D: true,
            zIndexBoost: false,
            dragClickables: true,
            allowContextMenu: true,
            throwProps: true,
            minimumMovement: 10,
            overshootTolerance: .3,
            edgeResistance: .8,
            throwResistance: this.vars.constrainDrag && 5000 || 1500,
            onClick: function(e) {
                e.button && e.button == 1  &&
                    e.preventDefault();
            },
            onDragStart: function() {

                self.isMoving = true;
                self.setBounds();
                gsap.set(self.dragElement, {cursor: 'grabbing'});
                !self.isTouched && self.touch();
                self.vars.snap && self.setSnapValues();
            },
            onDragEnd: function () {

                self.vars.constrainDrag ?
                    self.dragEndConstrained() :
                    self.dragEndUnconstrained();

            },
            onThrowEnd: function () {

                self.isMoving = false;
            },
            ...draggableVars
        };

        return this.init();

    }

    init() {

        // Must be relative or absolute in order to get offsetLeft
        gsap.set(this.dragElement, {
            position: 'relative'
        });

        this.draggable = Draggable.create(this.dragElement, this.draggableOptions)[0];
        window.addEventListener('resize', this.resizeHandler);
        this.setActive(this.vars.initialIndex);
        this.vars.snap && this.setSnapValues();
        this.setBounds();
        this.evaluateMinMax();

        this.resizeToken = ResizeListener.add({
            el: this.dragElement,
            cb: this.resizeHandler
        });

        return this;
    }

    setActive(index) {

        if(this.activeIndex === index) return this;

        const prevIndex = this.activeIndex;

        this.activeCell && this.activeCell.classList.remove(this.vars.activeCellClass);
        this.activeCell = this.cells[index];

        this.activeIndex = index;
        this.activeCell.classList.add(this.vars.activeCellClass);

        this.isFirst = index === 0;
        this.isLast = index === this.cells.length -1;

        this.emit(EVENTS.activate, {
            prevIndex,
            activeIndex: index,
            isFirst: this.isFirst,
            isLast: this.isLast
        });

        return this;
    }

    getCellOffset(cell) {

        const x = !this.vars.centered ?
            -(cell.offsetLeft) :
            -(
                cell.offsetLeft +
                ((cell.clientWidth - this.dragElement.clientWidth)/2)
            );

        return x;
    }

    dragEndConstrained() {

        const {startX, x:endX} = this.draggable;
        const movement = (startX - endX);
        if(!movement) return;

        const significant =  this.isMoving || Math.abs(movement) > 60;
        const forward =  endX < startX;
        const breachLeft = endX > this.bounds.maxX;
        const breachRight = endX < this.bounds.minX;

        if(!significant || breachLeft || breachRight) {
            this.goTo(this.activeIndex, .4);

            return;
        }

        this.goTo(this.activeIndex + (forward && 1 || -1))
    }

    evaluateMinMax(endX) {

        const {draggable:d} = this;

        // use endX if provided
        // else use draggable.endX if available
        // else use draggable's x position

        const end = Math.round(
            typeof endX == 'number'
                ? endX
                : typeof d.endX == 'number'
                    ? d.endX
                    : d.x
        );

        const atEnd = (end === Math.round(d.minX));
        const atStart = (end === Math.round(d.maxX));

        const shouldEmit = (atEnd !== this.atEnd)
            || (atStart !== this.atStart);

        this.atEnd = atEnd;
        this.atStart = atStart;

        const e = {
            atStart,
            atEnd
        };

        shouldEmit && this.emit('atEdge', e);
    }

    dragEndUnconstrained() {

        this.setActive(this.snapIndex || 0);
        this.evaluateMinMax();

        if(this.vars.snap && this.vars.centered) {

        }

        if(this.vars.snap && this.vars.centered) {

        }
    }

    goTo(index, duration = null) {

        !this.isTouched && this.touch();
        const clampedIndex = Math.max(0, Math.min(this.cells.length - 1, index));

        const prevActive = this.active;
        const prevActiveIndex = this.activeIndex;

        this.setActive(clampedIndex);

        this.emit(EVENTS.goTo,{
            duration,
            prevActive,
            prevActiveIndex,
            active: this.active,
            activeIndex: clampedIndex,
        });

        return this.moveTo(clampedIndex, duration);
    }

    moveTo(index, duration = null) {

        this.draggable.update();



        const cell = this.cells[index];
        const {bounds} = this;
        const targetOffset = this.getCellOffset(cell);
        const clampedOffset = Math.round(Math.max(bounds.minX , Math.min(bounds.maxX, targetOffset)));

        if(typeof duration !== 'number') {
            // calculate and clamp duration
            const calcDuration = Math.abs(this.draggable.x - clampedOffset) / this.vars.durationFactor;
            duration = Math.max(
                Math.min(this.vars.maxDuration, calcDuration),
                this.vars.minDuration
            )
        }

        gsap.to(this.dragElement, { duration: duration,
            x: clampedOffset,
            ease: Power2.easeOut,
            onComplete: ()=> {
                this.isMoving = false;
            }
        });

        this.evaluateMinMax(clampedOffset);

        return this;
    }

    resizeHandler = ()=> {

        requestAnimationFrame(()=> {

            this.setBounds();
            this.evaluateMinMax();
            this.moveTo(this.activeIndex);
        });
    };

    setSnapValues() {

        this.snapValues = this.cells
            .map(this.getCellOffset, this);
    }

    getTotalWidth() {

        const w = this.vars.cells.reduce((n, el) => {
            return n + el.getBoundingClientRect().width;
        }, 0);

        return w;
    }

    getBounds() {

        const bounds = {
            minX: Math.min(-( this.getTotalWidth() - this.dragElement.getBoundingClientRect().width), 0),
            maxX: 0
        };

        // console.log(-( this.getTotalWidth() - this.dragElement.getBoundingClientRect().width));

        return bounds;
    }

    setBounds() {

        this.bounds = this.getBounds();
        this.draggable.applyBounds(this.bounds);

        return this;
    }

    touch() {

        if(this.isTouched) return this;

        this.isTouched = true;
        this.vars.onFirstInteraction(this);
        this.vars.onFirstDrag(this);

        this.emit(EVENTS.interaction);

        return this;
    }

    destroy() {
        this.clear();
        this.resizeToken && ResizeListener.remove(this.resizeToken);
        this.draggable && this.draggable.kill();
    }
}

SimpleSlider.EVENTS = EVENTS;
