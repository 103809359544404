import React, {Component} from "react";
import ReactDOM from "react-dom";
import {C} from '../../Jesus'
import classNames from 'classnames';
import {Transition} from "react-transition-group";
import {modalIn, modalOut, panelIn, panelOut} from "./panelAnimations";

const noop = ()=> {};

const modalRoot = C('div', {'class':'Panel-root'});
document.body.Append(modalRoot);

export default class Panel extends Component {

    constructor(props) {
        super(props);
        this.type = props.type || 'Panel';
        this.el = C('div', {'class': `${this.type}-instance`});
        this.$inner = React.createRef();
        this.$overlay = React.createRef();

        switch (this.type) {
            case 'Modal':
                this.in = modalIn.bind(this);
                this.out = modalOut.bind(this);

                break;

            case 'Panel':
                this.in = panelIn.bind(this);
                this.out = panelOut.bind(this);
        }
    }

    static defaultProps = {
        opacity: .5,
        onClose: ()=> {},
        shouldLock: true
    };

    componentDidMount() {
        const {type} = this;
        document.documentElement.classList.add(`${type}-active`);
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        console.log(`${this.type} unmount`);
        modalRoot.removeChild(this.el);
        // todo: has no effect,remove or repurpose
        document.documentElement.classList.remove(`${this.type}-active`);
    }

    handleClose = () => {
        this.props.onClose()
    };

    render({onClose, className, isOpen}, state) {

        const {type} = this;

        return (
            ReactDOM.createPortal(
                <Transition
                    timeout={400}
                    appear={true}
                    mountOnEnter
                    unmountOnExit
                    in={isOpen}
                    onEnter={this.in}
                    onExit={this.out}
                >
                    <div className={classNames(className, `${type}`)}>
                        <div className={`${type}-overlay`} ref={this.$overlay} onClick={this.handleClose}/>
                        <div className={`${type}-inner`} ref={this.$inner}>
                            <div className={`${type}-content`}>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </Transition>
                , this.el
            )
        )
    }
}
