import { gsap } from "gsap";
import { Power3 } from "gsap";
import basket from "../modules/userLists/basket";
import {elementWithinTag} from "../functions";
import {C, Q} from '../Jesus';
import Events from "../events";
import request from 'superagent';

let instance;
let loader;

const createInstance = () => {

    instance = {
        mainContent: Q('.main-content'),
        menu: Q('.menu-canvas'),
        events: new Events,
        loggedIn: !Q('#login-form')
    };

    var promoRoot = Q('.cartUpgrader');

    if (promoRoot) {

        const perks = promoRoot.Q('.cartUpgrader-perks');
        promoRoot.InsertBefore(instance.mainContent.Q('.cart-items'));
        promoRoot.Q('.cartUpgrader-showPerks').addEventListener('click',
            e => {
                var startHeight = promoRoot.clientHeight;
                var tl = gsap.timeline();

                tl
                    .add(() => e.target.Remove())
                    .set(perks, {clearProps: 'display'})
                    .from(promoRoot, .5, {
                        height: startHeight,
                        ease: Power3.easeInOut
                    }, 0)
                    .staggerFrom(perks.QA('li'), .35, {
                        opacity: 0,
                        y: 60
                    }, .04, .1)
            });
    }

    const addLoader = (...args) => addGenericLoader(.7, ...args);

    function disableButtons() {
        toArray(instance.mainContent.getElementsByTagName('button')).forEach(function (button) {
            button.addEventListener('click', function (e) {
                clickHandler(e);
                return false;
            })
        })
    }

    disableButtons();

    instance.mainContent.addEventListener('click', clickHandler);

    function clickHandler(e) {

        var target,
            request,
            vars,
            form;

        //// a clicked //////////////

        if ((target = elementWithinTag(e.target, 'a'))) {

            target.href.indexOf('del_x') > -1 && get(target.href) && e.preventDefault() ||
            target.href.indexOf('dlvmode_id') > -1 && get(target.href) && e.preventDefault() ||
            target.href.endsWith('/handlekurv') && get(target.href) && e.preventDefault();

            return target;
        }

        //// button clicked //////////////

        if ((target = elementWithinTag(e.target, 'button'))) {
            switch (target.name) {
                case 'confirm_x':
                    e.preventDefault();

                    if (!window.glob.isLoggedIn) {
                        return;
                    }

                    const data = new FormData;
                    data.append(target.name, target.value)

                    vars = {
                        form: elementWithinTag(target, 'form'),
                        request: data
                    };

                    post(vars, 'step_1');

                    return target;

                case 'dlvchoose_x':
                    e.preventDefault();
                    vars = {form: Q('#dlvchoose')};
                    vars.request = new FormData(vars.form);

                    post(vars, 'step_2');

                    return target;

                case 'checkout_x':

                    e.preventDefault();
                    vars = {form: Q('#checkout')};
                    vars.request = new FormData(vars.form);
                    post(vars, 'step_3');

                    return target;
            }
        }

        if (elementWithinTag(e.target, 'iframe')) {
            console.log('iframe');
        }
    }

    function updateCartView(html) {

        var tmp = C('div').Html(html),
            newContent = tmp.Q('.main-content'),
            nav = Q('.nav'),
            err;

        instance.mainContent.remove();

        gsap.set(newContent, {
            opacity: .5
        });

        instance.mainContent = newContent.InsertAfter(nav);
        instance.mainContent.addEventListener('click', clickHandler);

        const cartItems = instance.mainContent.Q('.cart-items');
        const count = cartItems
            ? Number(cartItems.dataset.count)
            : 0;

        basket.setState({count});

        gsap.to(instance.mainContent, { duration: .6,
            opacity: 1,
            clearProps: 'all'
        });

        hideLoader(loader);

        //display errors
        (err = Q('#msg')) &&
        toArray(err.getElementsByClassName('message')).forEach(function (elem) {

            var severity = toArray(elem.classList).find(function (str) {
                return str !== 'message'
            });

            typeof vendo_message !== 'undefined' &&
            vendo_message(severity, elem.Remove().innerText)
        });

    }

    function get(url) {

        loader = addLoader();
        return request
            .get(url)
            .then(res => updateCartView(res.text))
    }

    function post(vars, step) {

        loader = addLoader();

        switch (step) {

            case 'step_1':
            case 'glass':
            case 'step_2':
            case 'coupon':
                request.post(vars.form.action)
                    // .type('form')
                    .send(vars.request)
                    .then(res => res.text)
                    .then(updateCartView)

                return true;

            case 'step_3':
                vars.form.submit();
                return true;

        }
    }

    // return instance API
    return instance
}

export default {
    getInstance: function () {
        return instance = (instance || createInstance());
    }
}
