import React, {render, useState} from 'react';
import {getFromStorage, latestListEvents} from "../../components/latest";
import favList from "./favList";
import basket from "./basket";

const Latest = ({initialCount, ...props}) => {

    const [count, setCount] = useState(getFromStorage().length);

    latestListEvents.addListener('count', setCount);

    return (<>
        {count || null}
    </>)
}

const Favorites = ({initialCount, ...props}) => {

    const [count, setCount] = useState(initialCount);

    favList.addListener('items', ({items}) => {
        setCount(items && items.length || 0);
    });

    return (<>
        {count || null}
    </>)
}

const Basket = ({initialCount, ...props}) => {

    const [count, setCount] = useState(initialCount);

    basket.addListener('count', ({count}) => {
        setCount(count);
    });

    return (<>
        {count || null}
    </>)
}

const components = {
    latest: Latest,
    favorites: Favorites,
    basket: Basket,
}

const listCounter = ({element:rootElement, data}) => {

    const {counter, ...props} = data;
    const Counter = components[counter];

    render(<Counter {...props}/>, rootElement);
};

export default listCounter;

