import { gsap } from "gsap";
import { Power1 } from "gsap";
import frameStore from "./modules/frameStore";
import {C, Q} from './Jesus';
import {elementWithinClass, withSelectorCallback} from "./functions";

export default () => {

    const rootEl = Q('.action-bar');

    const buttons = rootEl.QA('.button');
    const panels = rootEl.QA('.panel');
    const imageWrapper = Q('.workshop .image-wrapper');
    const imageContainer = Q('.image-container');
    const tabButtons = buttons.filter(b =>
        b.clientHeight > 10
    )

    const tabsData = tabButtons.map((b, i) => ({
        button: b,
        panel: b.parentElement.Q('.panel'),
        title: b.Q('.button-label').innerText,
        index: i
    }));

    const state = {
        isOpen: false,
        frameListIsTouched: false,
    }

    const initFrameList = () => {

        state.frameListIsTouched = true;
        frameStore.setState({awake: true});
    }

    const createPanelLink =  (direction, index, targetIndex) => {

        const backgroundImage = `/images/arrow-${direction}.svg`;
        const targetData = tabsData[targetIndex];

        const ret = C('a', {
            'class': `panelHeader-arrow panelHeader-arrow_${direction}`,
            style: `background-image: url(${backgroundImage})`
        }).Html(targetData.title);

        ret.onclick = function (e) {
            e.stopImmediatePropagation();
            tabButtons[targetIndex]?.click();
        };

        return ret;
    }

    const onOpen = () => {

        state.isOpen = true;
        window.addEventListener('click', handleWindowClick);

        !state.frameListIsTouched && initFrameList();
        document.querySelector('.workshop > .main').classList.add('panelOpen');

        // scroll image into view if scrolled past
        if(window.scrollY > (imageContainer.offsetTop - 10)) {
            gsap.to(window, { duration: .58,
                scrollTo: {y: 0},
                ease: Power1.easeInOut,
                delay: .11
            }, .33)
        }
    }

    const onClose = () => {

        state.isOpen = false;
        window.removeEventListener('click', handleWindowClick);
        document.querySelector('.workshop > .main').classList.remove('panelOpen');
    }

    const handleButtonClick = withSelectorCallback('.option .button', (e, clickedBtn) => {

        const isActive = clickedBtn.classList.contains('open');
        const willCLose = state.isOpen && isActive;
        const willOpen = !state.isOpen;

        rootEl.Q('.button.open')?.classList.remove('open');
        rootEl.Q('.panel.open')?.classList.remove('open');

        const thisPanel = clickedBtn.parentElement.Q('.panel');

        !isActive && thisPanel.classList.add('open');
        !isActive && clickedBtn.classList.add('open');

        willCLose && onClose();
        willOpen && onOpen();
    });

    const handleWindowClick = ({target}) => {

        if(!state.isOpen) return;

        if(
            elementWithinClass(target, 'action-bar') || // frame detail
            elementWithinClass(target, 'full-frame-button') || // frame detail
            elementWithinClass(target, 'image') || // main image
            (
                elementWithinClass(target, 'ctaBar-selection') &&
                !elementWithinClass(target, 'ctaBar-selection_product')
            ) || // cta selection, not product
            elementWithinClass(target, 'overlay', 6) || // frame detail lightbox
            elementWithinClass(target, 'featherlight', 8) // fethelight lightbox
        ) {
            return;
        }

        const activePanel = Q('.panel.open');
        const activeButton = Q('.button.open');

        activePanel && activePanel.classList.remove('open');
        activeButton && activeButton.classList.remove('open');

        activePanel && onClose();
    }

    tabsData.forEach((data, i) => {

        data.leftIndex = tabButtons[i -1] ? i -1 : tabButtons.length -1;
        data.rightIndex = tabButtons[i +1] ? i +1 : 0;
        data.panelHeader = data.panel.Prepend(
            C('div', {'class': 'panelHeader'}).Append(
                C('div', {'class': 'panelHeader-nav'}).Append(
                    createPanelLink('left', i, data.leftIndex),
                    createPanelLink('right', i, data.rightIndex),
                ),
                C('h3', {'class': 'panelHeader-h'}).Text(data.title),
            )
        );
    });

    var hexDigits = ["0","1","2","3","4","5","6","7","8","9","a","b","c","d","e","f"];

    function rgb2data(rgb) {
        return rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    }

    function rgb2hex(rgb) {
        rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
        return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
    }

    function hex(x) {
        return isNaN(x) ? "00" : hexDigits[(x - x % 16) / 16] + hexDigits[x % 16];
    }

    function rgbDataBrightness(d) {

        var rgb = [d[1], d[2], d[3]];

        typeof rgb[0] === "string" && (
            rgb = rgb.map(function (v) {
                return parseInt(v)
            })
        );

        var sum = rgb.reduce(function (a, b) {
            return a + b
        },0);

        return sum/(255*3);
    }

    var activeBrightnessClass = 'bright',
        brightnessClassNames = [
            'dark',
            'medium-bright',
            'bright'
        ];

    rootEl.addEventListener('click', withSelectorCallback('li.color', (e, target) => {
        var clr = getComputedStyle(target).backgroundColor;

        var hex = rgb2hex(clr),
            rgbData = rgb2data(clr),
            brightness = rgbDataBrightness(rgbData);

        var newBrightnessClass = brightnessClassNames[
        brightness > .65 && 2 ||
        brightness > .4 && 1 ||
        0
            ];

        imageWrapper.RemoveClass(activeBrightnessClass).AddClass(newBrightnessClass);
        activeBrightnessClass = newBrightnessClass;

        var darkColor = lightenDarkenColor(hex, -30);

        // Sets the background on both the background and the icon on the button
        var wrapper = Q('.workshop .image-wrapper');

        gsap.set(wrapper, {
            background: 'linear-gradient(' + hex + ' 0%,' + darkColor + ' 100%)'
        });

        gsap.set(Q('.button .active-color'), {
            backgroundColor: hex
        });
    }));

    function lightenDarkenColor(color, amt) {
        var usePound = false;
        var colorSliced = '';

        if (color[0] === '#') {
            colorSliced = color.slice(1);
            usePound = true;
        }

        var num = parseInt(colorSliced, 16);
        var red = (num >> 16) + amt;

        if (red > 255) red = 255;
        else if (red < 0) red = 0;

        var blue = ((num >> 8) & 0x00FF) + amt;

        if (blue > 255) blue = 255;
        else if (blue < 0) blue = 0;

        var green = (num & 0x0000FF) + amt;

        if (green > 255) green = 255;
        else if (green < 0) green = 0;

        return (usePound ? '#' : '') + (green | (blue << 8) | (red << 16)).toString(16);
    }

    window.addEventListener('click', handleButtonClick);

    return {
        tabsData,
        destroy() {
            window.removeEventListener('click', handleButtonClick);
            window.removeEventListener('click', handleWindowClick);
        }
    };
}
