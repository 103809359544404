import { gsap } from "gsap";
import { Power1 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

//to array

function toArray(o) {
    for (var a = [], l=o.length; l--;) a[l] = o[l];
    return a;
}


// get scrollbar width

function scrollToElement(element, vars) {

    if(!element)
        return;

    var defaults = {
        container: window,
        duration:.4,
        ease: Power1.easeInOut,
        offset: 20,
        edge: 'top',
        autoKill: false
    };

    vars = {
        ...defaults,
        ...(vars || {})
    };

    var scroll = vars.container.scrollTop || vars.container.scrollY;
    var innerHeight = vars.container.innerHeight || vars.container.clientHeight;

    var max = (function () {
        if(vars.container == window) {
            return document.body.scrollHeight - innerHeight;
        } else {
            return vars.container.scrollHeight - vars.container.clientHeight;
        }
    })();

    var edge = element.getBoundingClientRect()[vars.edge];

    edge = Math.min(
        Math.max(edge + scroll - vars.offset, 0),
        max
    );

    gsap.to(vars.container, { duration: vars.duration,
        scrollTo: {
            y: edge,
            autoKill: vars.autoKill
        },
        ease: vars.ease
    });
}

window.toArray = toArray;
window.scrollToElement = scrollToElement;
