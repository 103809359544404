import React, {useMemo} from 'react';
import Select, {SelectSummary} from "../Select";

export default ({onChange, value, options,}) => {

  const fields = useMemo(() => [
    {
      name: 'color',
      label: 'Farge',
    },
    {
      name: 'profile',
      label: 'Profil',
    },
    {
      name: 'width',
      label: 'Bredde',
    },
  ], []);

  return (
    <div className='FrameFilter'>
      {
        fields.map(({name, label}) => (
            <Select name={name}
                    key={name}
                    multi={true}
                    value={value[name]}
                    options={options[name]}
                    labelText={label}
                    onChange={onChange}
                    LabelComponent={SelectSummary}
                    className={`FrameFilter-Select FrameFilter-Select_${name}`}/>
        ))
      }
    </div>
  )
}