import request from 'superagent';

export default ({element}) => {

    const form = element.Q('form');
    const action = form.getAttribute('action');

    let clicked;

    const setClicked = ({target}) =>{

        clicked = target.tagName === 'BUTTON' && target || null;
        setTimeout(()=> clicked = null, 30)
    };

    const submit = (e) => {

        e.preventDefault();
        if(!clicked) return;

        const {name, value} = clicked;

        request.post(action)
            .send({[name]: value})
            .type('form')
            .then(()=> {});

        element.remove();
    };

    form.addEventListener('click', setClicked);
    form.addEventListener('submit', submit);
};
