
export const errToast = (agentError) => {

    const {response:{body:{messages = []} = {}} = {}} = agentError || {};

    if(messages) {
        messages.reverse().forEach(msg => {
            vendo_message('error', msg)
        })
    } else {
        vendo_message('error', 'Uspesifisert feil!')
        console.log(agentError);
    }
};
