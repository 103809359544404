import React, {useEffect, useMemo, useState, useCallback} from "react";
import FramePickerSelect from "./FramePickerSelect";
import SvgIcon from "../SvgIcon";
import Lazy from 'vanilla-lazyload';
import {sortByProp, thousandSeparate} from "../../functions";
import request from 'superagent';
import FrameFilter from "./FrameFilter";
import {E} from "../HelperComponents";
import FrameEntry from "./FrameEntry";
import filterOptions, {frameParser} from "./filterOptions";
import useStoreValue from "../../hooks/useStoreValue";

// todo: refactor/import
const priceFormat = (p) => thousandSeparate(p, ' ') + ',-';

export default ({store, types}) => {

  const {
    edoc_url_prefix: edocPrefix,
    is_bundled: isBundled,
    framing_type,
    item_id,
    product_id,
    recommended: recommendedFrames
  } = store.state;

  const initialFilterValue = {
    color: [],
    profile: [],
    width: []
  }

  const [filterValue, setFilterValue] = useState(initialFilterValue);

  const [frameList, setFrameList] = useStoreValue(store, 'list');
  const [selectedFrame, setSelectedFrame] = useStoreValue(store, 'selected_frame');
  const [activeFramingType, setActiveFramingType] = useStoreValue(store, 'framing_type')

  const lazyFrames = useMemo(() => new Lazy({
    elements_selector: ".FramePicker-entry-img"
  }), []);

  const framingTypes = useMemo(() => types.map(type => ({
    ...type,
    selected: type.value === framing_type.value
  })), [])

  const compatibleFrame = (frame) => {
    return frame.d_mm >= activeFramingType.min_frame_depth_mm;
  }

  const handleSelectChange = async (e) => {

    switch (e.target.name) {

      case 'framing_type':

        setActiveFramingType(
          framingTypes.find(type => type.value === Number(e.target.value))
        );

        break;
    }
  }

  const handleFilterChange = (e) => {

    const {target: {name, value, checked}} = e;

    const updValue = {
      ...filterValue,
      [name]: !checked
              ? filterValue[name].filter(v => v !== value)
              : [...filterValue[name], value]
    }

    setFilterValue(updValue);
  }

  const handleResetClick = e => {
    setFilterValue(initialFilterValue);
    e.stopPropagation();
  }

  const filter = useMemo(() => {

    const {color, profile, width} = filterValue;

    return (frame) => {

      const exclude = !compatibleFrame(frame) ||
        color.length && !color.includes(frame.color) ||
        profile.length && !profile.includes(frame.profile) ||
        width.length && !width.includes(frame.width);

      return !exclude;
    }

  }, [filterValue, frameList, activeFramingType])

  const handleFrameChange = useCallback((frameData) => {
    setSelectedFrame(frameData);
  }, [setSelectedFrame])

  useEffect(() => {

    const init = async () => {

      const res = await request.get('/api/frame_list')
        .query({item_id, product_id})
        .then(res => res.body);

      setFrameList(res.list.map(frameParser));

      return 1;
    }

    store.state.awake && init() ||
    store.once('awake', init);
  }, [])

  useEffect(() => {

    setTimeout(() => {
      lazyFrames.update()
    }, 250);

  }, [frameList, filter])

  const {
    price: framingPrice,
    name: framingName
  } = activeFramingType;

  const hasFilters = filterValue && Object.values(filterValue)
    .some(v => v && v.length);

  const Frame = useMemo(() => (props) => <FrameEntry {...{
      ...props,
      selected: selectedFrame,
      edocPrefix,
      onClick: handleFrameChange
    }}/>
  , [selectedFrame, handleFrameChange])

  const filteredFrameList = frameList &&
    frameList.filter(filter);

  return (
    <div className="FramePicker">
      <div className="FramePicker-inner">
        <div className="FramePicker-info">
          {selectedFrame && framingPrice && (
            <div className='-entry -entry_info'>
              Tillegg for teknikk: "{framingName}" kr {priceFormat(framingPrice)}
            </div>
          ) || null}
          {selectedFrame && !compatibleFrame(selectedFrame) && (
            <div className='-entry -entry_error'>
              <E>Valgt ramme er dessverre for smal til å brukes med valgt teknikk. Vennligst prøv en annen.</E>
            </div>
          ) || null}
        </div>
        <div className="FramePicker-head">
          <FramePickerSelect
            name='framing_type'
            className='FramePicker-select FramePicker-select_framingType'
            entries={framingTypes}
            selected={activeFramingType.value}
            onChange={handleSelectChange}
          />

          <FrameFilter onChange={handleFilterChange}
                       options={filterOptions}
                       value={filterValue}/>
        </div>

        {selectedFrame && (
          <div className='FramePicker-group'>
            <h3 className='FramePicker-group-h'>
              <span className='-text'>Ditt valg</span>
              <span className='-label -label_clickable' onClick={(e) => {
                e.stopPropagation();
                setSelectedFrame(null);
              }}>Fjern</span>
            </h3>
            <ul className='FramePicker-list'>
              <FrameEntry {...{...selectedFrame, edocPrefix, selected: null}} noLazyLoad/>
            </ul>
          </div>
        )|| null}

        {recommendedFrames && recommendedFrames.length && (
          <div className='FramePicker-group'>
            <h3 className='FramePicker-group-h'><span className='-text'>Vi anbefaler</span></h3>
            <ul className='FramePicker-list'>
              {
                recommendedFrames
                  .filter(compatibleFrame)
                  .map(Frame)
              }
            </ul>
          </div>
        ) || null}

        {filteredFrameList && filteredFrameList.length && (
          <div className='FramePicker-group'>
            <h3 className='FramePicker-group-h'><span className='-text'>Filtrert utvalg</span></h3>
            {hasFilters && (
              <ul className='FramePicker-list'>
                {
                  filteredFrameList
                    .sort(sortByProp('name'))
                    .map(Frame)
                }
              </ul>
            ) || null}
            {!hasFilters && (
              <div className='FramePicker-filterTease'>
                <E>Vi har <strong>{frameList.length}</strong> rammer som passer dette bildet. Begynn å filtrere for å finne din perfekte.</E>
              </div>
            ) || null}
          </div>
        ) || null}

        {!filteredFrameList || !filteredFrameList.length && (
          <div className='FramePicker-group'>
            <h3 className='FramePicker-group-h'><span className='-text'>Ingen treff</span></h3>
            <div className='FramePicker-info FramePicker-info_endSummary'>
              <div className='-entry -entry_info'>
                <strong>{frameList.length}</strong> <E>rammer passer dette bildet,
                men dessverre passer ingen akkurat denne valgkombinasjonen. Forsøk å gjøre noen justeringer.</E>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
