<template>
  <div>
    <div
      class="relative flex mt-4 pb-4 items-center w-4/6 md:max-w-prose mx-auto"
    >
      <div class="flex-grow border-t border-midnight"></div>
      <h1 class="flex-shrink mx-4 text-2xl md:text-4xl">
        Oppgrader fordelsnivå
      </h1>
      <div class="flex-grow border-t border-midnight"></div>
    </div>

    <p class="max-w-prose mx-auto text-center px-14 md:text-lg">
      Ikke gå glipp av gode rabatter, bonuspoeng, abonnement på Magasinet KUNST,
      invitasjoner til arrangementer og mye mer!
    </p>

    <div class="md:hidden max-w-sm mx-auto mt-14 px-6">
      <div>
        <RadioGroup v-model="selected">
          <carousel navigation
                    navigation-class="top-40"
                    navigation-button-prev-class="-ml-5"
                    navigation-button-next-class="-mr-5">
            <slide v-for="plan in plans" :key="plan.cgroup_id">
              <RadioGroupOption :value="plan.cgroup_id" as="div" class="flex">
                <BenefitCard
                  class="ui-checked:border-2 ui-checked:border-black ui-selected:p-4 hover:cursor-pointer"
                  :plan="plan"
                  :currentLevel="currentLevel"
                  expanded
                  show-level
                />
              </RadioGroupOption>
            </slide>
          </carousel>
        </RadioGroup>
      </div>
    </div>

    <form action="/myprofile" method="post">
      <div class="hidden md:block">
        <div>
          <RadioGroup
            v-model="selected"
            class="grid grid-cols-1 px-10 md:grid-cols-3 max-w-sm md:max-w-5xl mx-auto gap-6 mt-20"
          >
            <RadioGroupOption
              v-for="plan in plans"
              :key="plan.cgroup_id"
              :value="plan.cgroup_id"
              as="div"
              class="flex"
            >
              <BenefitCard
                class="ui-checked:border-2 ui-checked:border-black ui-selected:p-4 hover:cursor-pointer"
                :plan="plan"
                :currentLevel="currentLevel"
                expanded
                show-level
              />
            </RadioGroupOption>
          </RadioGroup>
        </div>
      </div>

      <div class="flex justify-center">
        <section class="my-10">
          <input type="hidden" name="cgroup_id" :value="selected" />
          <button
            type="submit"
            class="bg-black px-14 py-4 text-white font-medium disabled:bg-neutral-300 disabled:text-neutral-600 disabled:cursor-not-allowed"
            name="upgrade_x"
            value="1"
            :disabled="
              currentLevel == 3000 ||
              selected == 1000 ||
              selected == currentLevel
            "
          >
            Oppgrader fordelsnivå
          </button>

          <div
            v-if="
              selected == 1000 ||
              selected == currentLevel ||
              currentLevel == 3000
            "
            class="text-center mt-2"
          >
            Du kan kun oppgradere til et høyere nivå.
          </div>
        </section>
      </div>
    </form>
  </div>
  <input type="hidden" name="cgroup_id" :value="selected">
  <input type="hidden" name="upgrade_x" value="1" />
</template>

<script setup>
import BenefitCard from "./BenefitCard.vue";
import Carousel from "./Carousel.vue";
import Slide from "./Slide.vue";
import { ref } from "vue";
import { RadioGroup, RadioGroupOption } from "@headlessui/vue";

const props = defineProps(["current-level"]);
const selected = ref(props.currentLevel);
import { plans } from "../data/plan";
</script>
