export default {
    addListener: function(event, fn) {
        this[event].push(fn);
        return fn;
    },
    removeListener: function(event, fn) {
        var arr = this[event];
        arr.splice(arr.indexOf(fn),1);
    }
};
