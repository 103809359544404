import { gsap } from "gsap";
import React, {useState, useEffect, useRef} from "react";
import request from 'superagent';
import {E} from "./HelperComponents";
import ForgotPassword from './ForgotPassword';
import {modal} from "../modules/modal";

const Login = (props) => {

    const [input, setInput] = useState({
        username: props?.username || '',
        password: ''
    })

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(props?.msg || null);
    const url = '/ajax_login';

    const errorRef = useRef(null);

//    if (props?.msg) setError(props?.msg);

    useEffect(() => {

        if (!error || submitting) return;

        const tl = gsap.timeline();
        tl.set(errorRef.current, {opacity: 1})
            .to(errorRef.current, .3, {
                opacity: 0,
                repeat: 4,
                yoyo: true
            })
            .to(errorRef.current, .3, {opacity: 1})
    }, [submitting])

    const handleInput = ({target: {name, value}}) => {

        setInput({
            ...input,
            [name]: value
        })
    }

    const submit = async (extra = {}) => {

        if (submitting) return;

        setError(null);
        setSubmitting(true);

        const res = await request.post(url)
            .type('form')
            .send({
                ...input,
                ...extra,
            })
            .then(({body}) => body)

        setSubmitting(false);
        const {redir, text} = (res || {});

        !redir && setError((text || 'Ukjent feil oppstod'));
        redir && (document.location.href = redir.replace('Location: ', ''));
    }

    const handleClick = (e) => {
        e.preventDefault();
        const {target: {name}} = e;
        submit({[name]: 1})
    }

    const handleSubmit = (e, clicked = null) => {
        e.preventDefault();
        submit({login_x: 1})
    }

    const gotoForgotPassword = (e) => {
        e.preventDefault();
        modal.setComponent({
            Component: ForgotPassword
        }).open();
    }

    return (
        <div className="registration-container loginForm">
            <h2 className='loginForm-h text-3xl'><E>Logg inn eller registrer deg</E></h2>
            <p className="my-4" dangerouslySetInnerHTML={
                {
                    __html: `Du m&aring; registrere deg for &aring; lagre favoritter, by p&aring; og handle kunst.
                    Det er helt gratis, og du blir automatisk en del av <a style="text-decoration:underline;" href="/fordelsprogram">Fineart Fordelsprogram</a>`
                }
            }/>

            {error &&
            <div ref={errorRef} className="feedback loginForm-error">{error}</div> || null}

            <form id="login-form" method="post" className="loginForm-form" onSubmit={handleSubmit}>
                <div className="loginForm-fields">
                    <fieldset>
                        <div className="field">
                            <label className='field-label' htmlFor="username">Brukernavn</label>
                            <input id="username"
                                   className='field-input'
                                   onInput={handleInput}
                                   type="text"
                                   value={input.username}
                                   name="username"/>
                        </div>
                        <div className="field">
                            <label className='field-label' htmlFor="password">Passord</label>
                            <input id="password"
                                   className='field-input'
                                   onInput={handleInput}
                                   type="password"
                                   value={input.password}
                                   name="password"/>
                        </div>
                    </fieldset>
                    <a href="#" className="flex justify-end text-neutral-700 mb-4" onClick={gotoForgotPassword}>Glemt passord?</a>
                </div>
                <div className='loginForm-actions'>
                    <button id="login_x"
                            type="submit"
                            className="btn btn_primary bg-pink-600"
                            value="1"
                            disabled={submitting}
                            name="login_x">
                        Logg inn
                    </button>
                    <a className="btn" href="/register">Registrer meg</a>
                </div>
            </form>
        </div>
    )
}

export default Login;
