import {E, Nada} from "./HelperComponents";
import React, {useState, useRef, useEffect, useMemo} from "react";
import classnames from "classnames";
import SvgIcon from "./SvgIcon";
import {isElement} from "../functions";

export default (
  {
    className = '',
    options,
    value: selected,
    multi,
    onChange,
    labelText,
    LabelComponent = Nada,
    name
  }
) => {

  const outerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleClick = e => {
    setIsOpen(!isOpen);
  };

  const handleWindowClick = ({target}) => {
    !isElement(target, outerRef.current) && setIsOpen(false);
  }

  useEffect(() => {

    isOpen && window.addEventListener('click', handleWindowClick);

    return () => {
      !isOpen && window.removeEventListener('click', handleWindowClick)
    }
  }, [isOpen])

  return (
    <div ref={outerRef} className={classnames('Select', className, {
      Select_isOpen: isOpen
    })}>
      <div className="Select-inner">
        <div className="Select-toggle" onClick={handleToggleClick}>
          <div className='Select-toggle-labelText'>{labelText}</div>
          <div className='Select-toggle-labelComponent'>
            <LabelComponent {...{selected, options}}/>
          </div>
          <div className='Select-toggle-arrow'>
            <SvgIcon name='chevron_down'/>
          </div>
        </div>
        {isOpen && (
          <div className="Select-options" onChange={onChange}>
            {options.map(({value, labelText}) => (
              <label className='Select-option' key={value}>
                <div className="Select-option-field field">
                  <input name={name}
                         className='field-boolean'
                         checked={value === selected || Array.isArray(selected) && selected.includes(value)}
                         value={value}
                         type={multi ? 'checkbox' : 'radio'}/>
                  <div className='field-booleanLabel'><E>{labelText}</E></div>
                </div>
              </label>
            ))}
          </div>
        ) || null}
      </div>
    </div>
  )
}

/**
 *
 * @param selected {string[]|string}
 * @param options {{value: string|number, labelText: string}}
 * @return {JSX.Element}
 */
export const SelectSummary = ({selected, options}) => {

  const summary =
    Array.isArray(selected) ? selected.map(v => options.find(opt => opt.value === v)?.labelText)?.join(', ') :
    selected ? selected
             : null;

  return (
    <div className='Select-toggle-summary'>
      {summary}
    </div>
  )
}
