import shopItemsLayout from "../components/shopItemsLayout";
import Lazy from "vanilla-lazyload";
import imagesLoaded from "imagesloaded";

export default ({element:rootEl}) => {

    const container = rootEl.querySelector('.shopItems');
    const layout = shopItemsLayout(container);

    const lazyImages = new Lazy({
        elements_selector: ".shopItem-image_idle",
        callback_loaded: el => el.classList.remove('shopItem-image_idle')
    });

    const loaderEl = rootEl.querySelector('.loader');

    imagesLoaded(container.QA('img'), () => {
        loaderEl && loaderEl.remove();
        layout.layout();
    })
}